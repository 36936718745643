/* GENERAL STYLES */
* {
  margin: 0;
  padding: 0; }

/* - - - - - */
/* Headings */
/* - - - - -*/
h1 {
  font-size: 40px;
  font-weight: 400;
  color: #000000;
  font-family: "Slabo 27px", serif; }
  h1 a {
    font-size: 40px;
    color: #000;
    font-family: "Slabo 27px", serif; }
    h1 a:hover {
      color: #195020; }

h2 {
  font-size: 32px;
  font-weight: 400;
  color: #000000;
  font-family: "Slabo 27px", serif;
  margin-top: 30px;
  margin-bottom: 20px; }
  h2 a {
    font-size: 32px;
    color: #000;
    font-family: "Slabo 27px", serif; }
    h2 a:hover {
      color: #195020; }

h3 {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  font-family: "Slabo 27px", serif;
  margin-top: 35px;
  margin-bottom: 22px; }
  h3 a {
    font-size: 24px;
    color: #000;
    font-family: "Slabo 27px", serif; }
    h3 a:hover {
      color: #195020; }

h4 {
  font-size: 21px;
  font-weight: 400;
  color: #000000;
  font-family: "Slabo 27px", serif;
  margin-top: 26px;
  margin-bottom: 15px; }
  h4 a {
    font-size: 21px;
    color: #000;
    font-family: "Slabo 27px", serif; }
    h4 a:hover {
      color: #195020; }

h5 {
  font-size: 15px;
  font-weight: 400;
  color: #777;
  font-family: "Slabo 27px", serif;
  margin-top: 38px;
  margin-bottom: 27px; }
  h5 a {
    font-size: 15px;
    color: #777;
    font-family: "Slabo 27px", serif; }
    h5 a:hover {
      color: #195020; }

h6 {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  font-family: "Gudea", sans-serif;
  margin-top: 47px;
  margin-bottom: 24px; }
  h6 a {
    font-size: 16px;
    color: #000;
    font-family: "Gudea", sans-serif; }
    h6 a:hover {
      color: #195020; }

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0; }

.title--serif {
  font-family: "Gudea", sans-serif; }
  .title--serif a {
    font-family: "Gudea", sans-serif; }

h3.title--serif {
  font-size: 17px;
  font-weight: 700;
  color: #303133;
  margin-top: 40px;
  margin-bottom: 27px; }
  h3.title--serif:first-child {
    margin-top: 0; }
  h3.title--serif a {
    font-size: 17px;
    color: #303133;
    font-family: "Gudea", sans-serif; }
    h3.title--serif a:hover {
      color: #195020; }

h4.title--serif {
  font-size: 16px;
  font-weight: 700;
  color: #303133;
  margin-top: 41px; }
  h4.title--serif:first-child {
    margin-top: 0; }
  h4.title--serif a {
    font-size: 16px;
    color: #303133;
    font-family: "Gudea", sans-serif; }
    h4.title--serif a:hover {
      color: #195020; }

/* - - - - */
/* Content */
/* - - - - */
body {
  font-family: "Gudea", sans-serif;
  font-size: 16px;
  color: #333; }

p {
  font-family: "Gudea", sans-serif;
  font-size: 16px;
  color: #333;
  margin-bottom: 25px; }

a {
  font-family: "Gudea", sans-serif;
  font-size: 16px;
  color: #195020;
  text-decoration: none;
  transition: color .4s; }
  a:hover {
    text-decoration: none;
    color: #000000; }

time {
  font-size: 13px;
  color: #909090; }

.link-arrow,
.download-link {
  font-size: 16px;
  font-weight: 700;
  color: #195020;
  line-height: 17px;
  display: inline-block;
  position: relative; }
  .link-arrow:after, .link-arrow:before,
  .download-link:after,
  .download-link:before {
    font-family: fontello;
    font-size: 12px;
    display: inline-block; }

.link-arrow:hover:after {
  margin-left: 10px; }

.link-arrow:after {
  content: '\ede6';
  margin-left: 5px;
  transition: margin-left 250ms ease-in; }

.download-link {
  margin-top: 10px; }
  .download-link:before {
    content: '\ede4';
    margin-left: 0;
    margin-right: 5px;
    transition: transform 250ms ease-in; }
  .download-link:hover:before {
    transform: translateY(5px); }
  .download-link:after {
    content: none; }

label {
  display: block;
  margin: 0; }

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  transition: border-color 0.25s ease-in-out; }
  [type='text']:focus,
  [type='password']:focus,
  [type='date']:focus,
  [type='datetime']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='week']:focus,
  [type='email']:focus,
  [type='number']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='url']:focus,
  [type='color']:focus,
  textarea:focus,
  select:focus {
    border: 1px solid #8a8a8a;
    outline: none; }

select {
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  appearance: none; }

.light-grey-bg {
  background-color: #fafafa; }

/* - - - */
/* Lists */
/* - - - */
ul {
  list-style: none; }
  ul li {
    position: relative;
    color: #333; }
    ul li:before {
      content: '\2022';
      position: absolute;
      max-width: 0;
      max-height: 0;
      left: -19px;
      top: -7px;
      font-size: 24px;
      color: #195020; }
  ul--no-b-marg {
    margin-bottom: 0; }
  ul--no-bullets {
    padding-left: 0; }
    ul--no-bullets li:before {
      display: none; }

ol,
ul {
  padding-left: 18px;
  margin-top: 1em;
  margin-bottom: 1em; }
  ol li,
  ul li {
    line-height: 1.6; }

/* - - - -*/
/* Tables */
/* - - - -*/
.table-wrapper {
  overflow-x: auto; }
  @media screen and (min-width: 40em) {
    .table-wrapper {
      overflow-x: initial; } }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 24px; }
  table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  table thead,
  table tfoot {
    background-color: #f7f7f7;
    border: none;
    color: #000; }
    table thead th,
    table thead td,
    table tfoot th,
    table tfoot td {
      padding: 1.2rem 1.9rem 1.1rem;
      text-align: left; }
    table thead tr,
    table tfoot tr {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
  table tbody th,
  table tbody td {
    padding: .4rem 1.9rem .4rem;
    color: #303133; }
  table tbody tr {
    background-color: #f7f7f7;
    cursor: pointer; }
    table tbody tr:nth-child(even) {
      background-color: #f7f7f7;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    table tbody tr:last-child {
      border: none; }
    table tbody tr:hover {
      background-color: #303133; }
      table tbody tr:hover td {
        color: #fff; }

/* - - - - */
/* Buttons */
/* - - - - */
.btn {
  display: inline-block;
  position: relative;
  font-weight: 700;
  font-family: "Gudea", sans-serif;
  font-size: 16px;
  padding: 14px 20px;
  color: white;
  background-color: #195020;
  border: none;
  z-index: 1;
  vertical-align: middle;
  box-shadow: inset 0 0 0 2px #195020, 0 0 1px transparent;
  transition: all .3s; }
  .btn:hover, .btn:focus, .btn:active {
    color: #fff; }
    .btn:hover:after, .btn:focus:after, .btn:active:after {
      width: 100%; }
  .btn:after {
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: #2e2e30;
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s; }
  .btn--small {
    font-size: 14px;
    padding: 10px 15px; }
    .btn--small.btn--ghost {
      padding-right: 26px; }
      .btn--small.btn--ghost:before {
        font-size: 9px;
        right: 14px;
        margin-top: 4px; }
  .btn--large {
    font-size: 20px;
    padding: 17px 25px; }
    .btn--large.btn--ghost {
      padding-right: 43px; }
      .btn--large.btn--ghost:before {
        right: 24px;
        font-size: 14px;
        margin-top: 4px; }
  .btn--small-medium {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-top: 10px;
    padding-bottom: 11px; }
    @media screen and (min-width: 40em) {
      .btn--small-medium {
        margin-top: 0; } }
    @media screen and (min-width: 64em) {
      .btn--small-medium {
        margin-bottom: 0; } }
  .btn--ghost {
    background-color: transparent;
    color: #195020;
    position: relative;
    padding-right: 35px;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.1s;
    box-shadow: inset 0 0 0 2px #d2d2d2, 0 0 1px transparent; }
    .btn--ghost:before {
      content: '\ede6';
      font-family: "fontello";
      font-size: 12px;
      position: absolute;
      right: 20px;
      margin-top: 3px;
      transform: translateZ(0);
      transition-duration: 0.1s;
      transition-property: transform;
      transition-timing-function: ease-out;
      transition-delay: .25s; }
    .btn--ghost:hover:before, .btn--ghost:focus:before, .btn--ghost:active:before {
      transform: translateX(4px); }

button[type="submit"] {
  cursor: pointer; }

.dark-section .btn:hover, .dark-section .btn:focus, .dark-section .btn:active {
  color: #195020; }
  .dark-section .btn:hover:after, .dark-section .btn:focus:after, .dark-section .btn:active:after {
    background-color: #fff; }

.socials *[class^=icon-] {
  color: #b4b4b4;
  width: 35px;
  height: 35px;
  font-size: 18px;
  border-radius: 50%;
  text-align: center;
  transition: .2s;
  display: inline-block; }
  .socials *[class^=icon-]:before {
    line-height: 35px; }
  .socials *[class^=icon-]:hover {
    color: #fff; }

.socials > a {
  display: inline-block; }

.socials--slide-hover {
  max-height: 30px;
  overflow: hidden; }
  .socials--slide-hover *[class^=icon-] {
    transform: translateY(-100%);
    display: block; }
  .socials--slide-hover a {
    line-height: 30px;
    display: inline-block;
    margin-right: -4px; }
    .socials--slide-hover a:hover *[class^=icon-]:not(.icon-hover) {
      transform: translateY(0); }
    .socials--slide-hover a:hover .icon-hover {
      transform: translateY(0);
      color: #fff; }
  .socials--slide-hover.icon-hover {
    color: #fff;
    transform: translateY(-100%); }

@media screen and (max-width: 1024px) {
  .socials--medium-marg {
    margin-top: 20px; } }

.row-logo {
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px; }
  @media screen and (min-width: 40em) {
    .row-logo {
      margin-top: 0;
      margin-bottom: 0; } }

.contact-phone {
  color: #333;
  font-size: 15px; }
  @media screen and (min-width: 40em) {
    .contact-phone {
      margin-right: 8px; } }
  .contact-phone span {
    color: #000000;
    font-size: 15px; }
  .contact-phone .icon-phone-3 {
    color: #b4b4b4;
    font-size: 18px; }
  .contact-phone--home-2 {
    color: #b4b4b4; }
    .contact-phone--home-2 span {
      color: #b4b4b4; }

h1.banner-title, h2.banner-title, h3.banner-title, h4.banner-title, h5.banner-title, h6.banner-title {
  margin: 30px 0 20px;
  text-align: center;
  font-size: 30px; }
  @media screen and (min-width: 40em) {
    h1.banner-title, h2.banner-title, h3.banner-title, h4.banner-title, h5.banner-title, h6.banner-title {
      margin: 37px 0 27px; } }
  @media screen and (min-width: 64em) {
    h1.banner-title, h2.banner-title, h3.banner-title, h4.banner-title, h5.banner-title, h6.banner-title {
      margin: 67px 0 47px; } }

h1.block-title, h2.block-title, h3.block-title, h4.block-title, h5.block-title, h6.block-title {
  margin: 40px 0 28px; }
  @media screen and (max-width: 1024px) {
    h1.block-title, h2.block-title, h3.block-title, h4.block-title, h5.block-title, h6.block-title {
      margin: 15px 0 20px; } }
  h1.block-title--no-t-marg, h2.block-title--no-t-marg, h3.block-title--no-t-marg, h4.block-title--no-t-marg, h5.block-title--no-t-marg, h6.block-title--no-t-marg {
    margin-top: 0; }
  h1.block-title--center, h2.block-title--center, h3.block-title--center, h4.block-title--center, h5.block-title--center, h6.block-title--center {
    text-align: center; }

.s-back-switch {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

/* - - - - - -*/
/* Blockquote */
/* - - - - - -*/
.blockquote,
blockquote {
  border-left: none;
  margin: 27px 0 20px;
  font-family: "PT Serif", serif;
  font-size: 18px;
  font-weight: 700;
  color: #303133;
  position: relative;
  font-style: italic; }
  @media screen and (min-width: 64em) {
    .blockquote,
    blockquote {
      padding-left: 52px;
      line-height: 30px; } }
  .blockquote::before,
  blockquote::before {
    content: open-quote;
    font-size: 72px;
    color: #195020;
    line-height: 0;
    position: relative;
    display: inline-block;
    bottom: 15px;
    left: -12px; }
  .blockquote:after,
  blockquote:after {
    color: transparent;
    content: close-quote;
    position: absolute; }

.blockquote-author__name:before, .blockquote-author__position:before {
  display: none; }

.blockquote-author__name {
  font-size: 18px;
  font-weight: 700;
  color: #195020;
  line-height: 1;
  font-style: normal;
  font-family: "Gudea", sans-serif; }

.blockquote-author__position {
  font-size: 16px;
  font-weight: 400;
  color: #909090;
  margin-top: 5px; }

.blockquote-author {
  display: flex;
  align-items: center; }

.blockquote-author__photo {
  max-width: 45px;
  max-height: 45px;
  height: auto;
  margin-right: 15px;
  margin-bottom: 0; }

.blockquote-author__info {
  display: flex;
  flex-direction: column; }

.blockquote--style-1 {
  background-color: #f7f7f7;
  padding: 23px 30px 35px; }
  .blockquote--style-1, .blockquote--style-1 p {
    font-weight: 400;
    font-size: 17px; }
    @media screen and (min-width: 64em) {
      .blockquote--style-1, .blockquote--style-1 p {
        line-height: 28px; } }
  .blockquote--style-1 p {
    display: inline;
    margin: 0;
    padding: 0; }
  .blockquote--style-1:after {
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    bottom: -20px;
    border: 10px solid;
    border-color: #ebebeb transparent transparent #ebebeb; }

.blockquote--style-2 {
  padding: 0; }
  @media screen and (min-width: 64em) {
    .blockquote--style-2 {
      padding-left: 0; } }

mark {
  color: #fff; }
  mark.main-color {
    background-color: #195020; }
  mark.minor-color {
    background-color: #303133; }

/* WIDGET */
.widget {
  margin-bottom: 30px; }
  @media screen and (min-width: 64em) {
    .widget {
      margin-bottom: 55px; } }
  .widget-title {
    font-size: 17px;
    font-weight: 700;
    color: #303133;
    font-family: "Gudea", sans-serif; }
    .widget-title a {
      font-family: "Gudea", sans-serif; }
    .widget-title--margins-2 {
      margin-bottom: 25px; }
  .widget ul {
    padding-left: 0;
    list-style: none;
    margin-left: 0; }
    .widget ul li {
      padding: 5px 0;
      padding-right: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .widget ul li:before {
        content: none; }
      .widget ul li:last-child {
        border-bottom: none; }
      .widget ul li a {
        font-size: 16px;
        color: #303133; }
        .widget ul li a:hover {
          color: #195020; }
        .widget ul li a.widget__date {
          font-family: "Gudea", sans-serif;
          font-size: 13px;
          font-weight: 400;
          color: #909090; }
          .widget ul li a.widget__date:hover {
            color: #000; }
      .widget ul li h4 {
        line-height: 24px;
        margin-bottom: 10px;
        margin-top: -5px; }
        .widget ul li h4 a {
          font-size: 16px;
          font-weight: 700;
          color: #303133;
          font-family: "Gudea", sans-serif; }
          .widget ul li h4 a:hover {
            color: #195020; }

.dark-section .widget ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #c7cc3c; }
  .dark-section .widget ul li:last-child {
    border-bottom: none; }
  .dark-section .widget ul li a {
    color: #b4b4b4; }
    .dark-section .widget ul li a:hover {
      color: #c7cc3c; }

.dark-section .widget h4 a {
  color: #b4b4b4; }

/* COMMON ELEMENTS */
.btn-columns .btn {
  margin-bottom: 7px; }

h3.element-title {
  margin-top: 71px;
  margin-top: 24px;
  margin-bottom: 30px; }

.slider-3 {
  margin-top: 45px; }
  .slider-3 img {
    margin-bottom: 0; }

.honors-awards {
  margin-top: 50px; }
  @media screen and (min-width: 40em) {
    .honors-awards {
      margin-top: 74px; } }
  @media screen and (min-width: 64em) {
    .honors-awards {
      margin-bottom: 147px; } }
  .honors-awards__title {
    margin-bottom: 35px; }
  @media screen and (min-width: 40em) {
    .honors-awards--about {
      margin-top: 35px; } }
  @media screen and (min-width: 40em) {
    .honors-awards--home-3 {
      margin-bottom: 30px; } }
  @media screen and (min-width: 64em) {
    .honors-awards--home-3 {
      margin-bottom: 0;
      margin-top: 103px; } }

.row--people-page {
  margin-bottom: 50px; }

.row--location {
  justify-content: center; }
  @media screen and (min-width: 64em) {
    .row--location {
      justify-content: flex-start; } }

.overlay {
  position: relative; }
  .overlay:before {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 0;
    background-color: rgba(19, 19, 21, 0.5); }

.b-breadcrumbs {
  list-style: none;
  margin-bottom: 10px; }
  @media screen and (min-width: 40em) {
    .b-breadcrumbs {
      margin-bottom: 25px; } }
  @media screen and (min-width: 64em) {
    .b-breadcrumbs {
      margin-bottom: 55px; } }
  .b-breadcrumbs li {
    display: inline-block;
    color: #333; }
    .b-breadcrumbs li:before {
      content: none; }
    .b-breadcrumbs li, .b-breadcrumbs li a {
      font-family: "Gudea", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 50px; }
    .b-breadcrumbs li:after {
      content: '\ede6';
      font-family: "fontello";
      font-size: 10px;
      display: inline-block;
      margin: 0 4px; }
    .b-breadcrumbs li:last-child:after {
      display: none; }
  @media screen and (min-width: 64em) {
    .b-breadcrumbs--marg-b {
      margin-bottom: 45px; } }

.single-content__img {
  margin-bottom: 40px; }

.single-content h2.single-content__title {
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 30px; }

.single-content__title {
  margin: 15px 0; }
  .single-content__title--in-text {
    margin-top: 30px;
    margin-bottom: 0px; }
    .single-content__title--in-text + .single-content__paragraph {
      margin-top: 15px; }

.single-content__paragraph {
  margin: 20px 0; }
  .single-content__paragraph:last-child {
    margin-bottom: 0; }

.single-practice {
  margin-bottom: 65px; }
  @media screen and (max-width: 1024px) {
    .single-practice {
      margin-bottom: 30px; } }

.testimonial-item {
  margin-bottom: 50px; }
  .testimonial-item--style-2 {
    margin-bottom: 35px; }
  .testimonial-item .blockquote:not(.blockquote--style-2) {
    padding-left: 30px; }

.table-lead {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px; }
  .table-lead a {
    font-size: 14px;
    text-decoration: underline; }

.slider-1 img {
  margin-bottom: 0; }

h3.accordion-main-title {
  margin-top: -6px;
  margin-bottom: 12px; }

h3.buttons-main-title,
h4.address-main-title {
  margin-top: 71px; }

h3.drop-caps-main-title {
  margin-top: 68px; }

h3.lists-main-title {
  margin-top: 43px;
  margin-bottom: 7px; }

h3.testimonials-main-title {
  margin-top: 47px;
  margin-bottom: 20px; }

h3.tables-main-title {
  margin-top: 37px;
  margin-bottom: 0; }

h3.highlights-main-title {
  margin-top: 43px;
  margin-bottom: 22px; }

h3.separators-main-title {
  margin-top: 46px;
  margin-bottom: -11px; }

h3.alert-boxes-main-title {
  margin-top: 31px; }

h3.socials-main-title {
  margin-top: 60px; }

h3.clients-main-title {
  margin-top: 43px; }

h3.gallery-main-title {
  margin-top: 53px;
  margin-bottom: 23px; }

.elements-wrapper-row {
  margin-top: 73px;
  padding-bottom: 101px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  margin-bottom: 97px; }

.elements-blockquote-row .widget-blockquote .widget-title {
  display: none; }

.our-people-wrapper.our-people-wrapper--elements {
  padding-bottom: 40px; }
  @media screen and (min-width: 64em) {
    .our-people-wrapper.our-people-wrapper--elements {
      padding-bottom: 40px; } }

#tx_cookies {
  display: none;
  position: fixed;
  width: calc(100% - 2rem);
  max-width: 30rem;
  bottom: 1rem;
  left: 1rem;
  z-index: +10; }
  #tx_cookies #tx_cookies_inner, #tx_cookies .typo3-messages {
    border: 2px solid #fff;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.5);
    background: rgba(25, 80, 32, 0.95); }
    #tx_cookies #tx_cookies_inner, #tx_cookies #tx_cookies_inner h3, #tx_cookies #tx_cookies_inner h4, #tx_cookies #tx_cookies_inner a, #tx_cookies .typo3-messages, #tx_cookies .typo3-messages h3, #tx_cookies .typo3-messages h4, #tx_cookies .typo3-messages a {
      color: #fff; }
    #tx_cookies #tx_cookies_inner h3, #tx_cookies .typo3-messages h3 {
      margin-bottom: 1rem; }
    #tx_cookies #tx_cookies_inner p, #tx_cookies .typo3-messages p {
      margin-bottom: .5rem;
      color: #fff; }
      @media screen and (max-width: 639px) {
        #tx_cookies #tx_cookies_inner p, #tx_cookies .typo3-messages p {
          font-size: .9rem; } }
    #tx_cookies #tx_cookies_inner a, #tx_cookies .typo3-messages a {
      text-decoration: underline; }
    #tx_cookies #tx_cookies_inner button, #tx_cookies #tx_cookies_inner .button, #tx_cookies .typo3-messages button, #tx_cookies .typo3-messages .button {
      background: #195020;
      color: #fff;
      padding: .5rem;
      border: 1px solid #fff; }
      #tx_cookies #tx_cookies_inner button.btn--ghost, #tx_cookies #tx_cookies_inner .button.btn--ghost, #tx_cookies .typo3-messages button.btn--ghost, #tx_cookies .typo3-messages .button.btn--ghost {
        padding-right: 2.5rem; }
      @media screen and (max-width: 639px) {
        #tx_cookies #tx_cookies_inner button, #tx_cookies #tx_cookies_inner .button, #tx_cookies .typo3-messages button, #tx_cookies .typo3-messages .button {
          margin-bottom: 1rem; } }
  #tx_cookies #tx_cookies_showPermanent {
    bottom: 0;
    left: 0;
    top: auto; }
  #tx_cookies form {
    display: inline-block;
    margin-right: 1rem; }
  #tx_cookies .typo3-messages {
    list-style: none;
    padding: .5rem; }

@media screen and (max-width: 639px) {
  #js-menu-sticky-anchor {
    display: none; } }

/* COMMON */
.no-scroll {
  overflow: hidden; }

.block-wrapper {
  background-color: #fafafa;
  margin-bottom: 30px; }
  @media screen and (min-width: 40em) {
    .block-wrapper {
      margin-bottom: 50px; } }
  @media screen and (min-width: 64em) {
    .block-wrapper {
      margin-bottom: 0;
      padding-bottom: 20px; } }

/* - - - - - - - - - - - - - - - - - - - - */
/* Header */
/* - - - - - - - - - - - - - - - - - - - - */
.main-header {
  position: relative;
  z-index: 6; }

.sticky-container {
  position: relative;
  z-index: 20; }

.row-fluid--menu {
  border-top: 1px solid #dadada;
  box-shadow: 0 4px 2px -2px rgba(107, 107, 107, 0.3); }
  .row-fluid--menu .menu-search-row {
    margin: 0;
    padding: 15px 0;
    height: 100%; }
  .row-fluid--menu .top-bar,
  .row-fluid--menu .title-bar {
    width: 100%; }
  .row-fluid--menu .title-bar {
    justify-content: flex-start; }
    .row-fluid--menu .title-bar .menu-icon {
      margin-left: .35rem;
      margin-right: .35rem; }
      .row-fluid--menu .title-bar .menu-icon:after {
        background: #cacaca;
        box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }
  .row-fluid--menu .top-bar {
    z-index: 10; }
    .row-fluid--menu .top-bar .top-bar-right {
      order: -1; }
    @media screen and (min-width: 40em) {
      .row-fluid--menu .top-bar {
        flex-wrap: wrap; }
        .row-fluid--menu .top-bar .top-bar-left {
          flex: 0 0 100%; }
        .row-fluid--menu .top-bar .top-bar-right {
          flex: 1 0 100%; } }
    @media screen and (min-width: 64em) {
      .row-fluid--menu .top-bar .top-bar-left {
        flex: 1 0 auto; }
      .row-fluid--menu .top-bar .top-bar-right {
        flex: 0 1 auto;
        order: 0; } }

.row-fluid--socials {
  background-color: #195020; }
  .row-fluid--socials #logoHeader {
    display: none; }
    @media screen and (max-width: 639px) {
      .row-fluid--socials #logoHeader {
        display: inline-block;
        margin: 0 1rem 0 .7rem; } }
  @media screen and (max-width: 639px) {
    .row-fluid--socials > .row {
      flex-direction: column;
      padding: 15px 0; } }
  .row-fluid--socials .socials {
    display: inline-flex; }
    .row-fluid--socials .socials a {
      display: inline-block; }
    .row-fluid--socials .socials *[class^=icon-] {
      border-radius: 0;
      font-size: 14px;
      width: 30px;
      height: 30px;
      display: block; }
      .row-fluid--socials .socials *[class^=icon-]:before {
        line-height: 30px; }

.header-3 .row-fluid--socials {
  background-color: #e1e1e1; }

.header-3 .row-fluid--menu {
  border: none;
  box-shadow: none; }

.header-3 .row-fluid .row--menu-bg {
  margin: 0;
  background-color: #3a3a3c;
  box-shadow: 0 4px 2px -2px rgba(107, 107, 107, 0.3); }

.header-3 .main-menu > li > a {
  color: #fff;
  background-color: #3a3a3c; }
  .header-3 .main-menu > li > a:after {
    opacity: .2;
    z-index: 1; }

.header-3 .nav-menu-icon i {
  background-color: #fff; }
  .header-3 .nav-menu-icon i:before, .header-3 .nav-menu-icon i:after {
    background-color: #fff; }

/* MAIN NAVIGATION AND MENU */
.main-navigation {
  position: relative;
  min-height: 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch; }
  @media screen and (min-width: 64em) {
    .main-navigation {
      flex-direction: row;
      align-items: center; } }
  @media screen and (max-width: 1024px) {
    .main-navigation.active {
      background-color: #fff;
      z-index: 2; } }
  @media screen and (max-width: 639px) {
    .main-navigation--home-2 {
      flex-direction: column;
      justify-content: space-between; } }
  @media screen and (min-width: 40em) {
    .main-navigation--home-2 {
      flex-direction: column;
      justify-content: space-between; } }
  @media screen and (min-width: 64em) {
    .main-navigation--home-2 {
      flex-direction: row; } }
  .main-navigation--home-2 .menu-search-row {
    padding: 0; }
  .main-navigation--home-2 .main-menu {
    justify-content: flex-end; }
    .main-navigation--home-2 .main-menu .sub-menu,
    .main-navigation--home-2 .main-menu .mega-sub-menu {
      margin-top: 19px; }
  .main-navigation--home-2 .menu-search-col .search-form {
    top: 63px; }
  @media screen and (max-width: 639px) {
    .main-navigation.row-menu--home-2 {
      flex-direction: column;
      padding: 15px 0; } }
  @media screen and (min-width: 40em) {
    .main-navigation.row-menu--home-2 {
      flex-direction: column;
      padding: 15px 0; } }
  @media screen and (min-width: 1200px) {
    .main-navigation.row-menu--home-2 {
      flex-direction: row;
      padding: 19px 0; } }
  .main-navigation.row-menu--home-2 .logo {
    text-align: center; }
    @media screen and (max-width: 1199px) {
      .main-navigation.row-menu--home-2 .logo {
        padding: 15px 0; } }

.logo img {
  margin-bottom: 0;
  width: 3rem; }

.logo a {
  color: #262626;
  font-family: Cabin, Arial, sans-serif;
  font-size: 34px;
  font-weight: 600; }

.main-menu {
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  /* Active menu items */ }
  .main-menu li {
    position: relative; }
    .main-menu li:before {
      content: none; }
    @media screen and (min-width: 64em) {
      .main-menu li:hover > a {
        color: #ffffff;
        background-color: #195020; } }
    .main-menu li a {
      position: relative;
      display: flex;
      flex-flow: row nowrap; }
  .main-menu > li {
    position: relative;
    flex: 0 0 auto; }
    .main-menu > li:last-child > a:after {
      background-color: transparent; }
    .main-menu > li > a {
      font-size: 17px;
      color: #000000;
      font-family: "Slabo 27px", serif;
      line-height: 1;
      position: relative; }
      @media (min-width: 64em) {
        .main-menu > li > a:after {
          content: '';
          position: absolute;
          right: -1px;
          top: 23px;
          height: 15px;
          width: 1px;
          background-color: #d5d5d6; } }
      @media screen and (min-width: 1024px) {
        .main-menu > li > a:hover {
          color: #ffffff;
          background-color: #195020; } }
      .main-menu > li > a:hover:after {
        background-color: transparent; }
    .main-menu > li a {
      transition: .2s;
      padding: 1.4rem; }
    .main-menu > li.current-menu-parent > a, .main-menu > li.current-menu-item > a {
      color: #ffffff;
      background-color: #195020; }
      .main-menu > li.current-menu-parent > a:after, .main-menu > li.current-menu-item > a:after {
        background-color: transparent; }
  .main-menu .current-menu-parent:after,
  .main-menu .current-menu-item:after,
  .main-menu .sub-menu .menu-item.current-menu-item:after {
    background-color: transparent; }
  .main-menu .current-menu-parent > a,
  .main-menu .current-menu-item > a,
  .main-menu .sub-menu .menu-item.current-menu-item > a {
    color: #ffffff;
    background-color: #195020; }
  .main-menu .sub-menu {
    display: none;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    background-color: #3a3a3c;
    margin: 0;
    box-shadow: inset 0 10px 5px -5px rgba(24, 24, 24, 0.3);
    min-width: 280px; }
    .main-menu .sub-menu .menu-item a {
      font-size: 14px;
      color: #b4b4b4;
      padding-top: 10px;
      padding-bottom: 10px; }
      @media screen and (min-width: 1024px) {
        .main-menu .sub-menu .menu-item a:hover {
          background-color: #195020;
          color: #fff; } }

/* Show second and third menu levels */
@media screen and (min-width: 768px) {
  .main-menu .sub-menu li {
    display: block; } }

@media screen and (min-width: 1024px) {
  .main-menu .sub-menu .sub-menu {
    position: absolute;
    left: 100%;
    top: 0; } }

/* Responsive menu button */
.nav-menu-icon {
  left: 5px;
  top: 0;
  width: 32px;
  height: 32px;
  display: none;
  cursor: pointer; }

.nav-menu-icon-col {
  display: none; }

/* Don`t reqiured */
.main-navigation.active .nav-menu-icon i {
  background: none; }

.main-navigation.active .nav-menu-icon i:before {
  top: 0;
  transform: rotate(45deg); }

.main-navigation.active .nav-menu-icon i:after {
  top: 0;
  transform: rotate(135deg); }

/* End Don`t reqiured */
.nav-menu-icon i:before, .nav-menu-icon i:after {
  content: '';
  width: 32px;
  height: 2px;
  background: #000;
  position: absolute;
  left: 0;
  transition: all 0.15s ease-out; }

.nav-menu-icon i:before {
  top: -7px; }

.nav-menu-icon i:after {
  bottom: -7px; }

.nav-menu-icon i {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 2px;
  background: #000; }

.menu-item-has-children > a span {
  display: none; }

/* Responsive menu */
@media screen and (max-width: 1023px) {
  .nav-menu-icon,
  .nav-menu-icon-col {
    display: block; }
  .main-menu {
    display: none; }
  .main-navigation.active .main-menu {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 0;
    height: auto;
    margin-top: 15px; }
  .main-menu li {
    display: block; }
  .main-menu .sub-menu {
    position: static;
    padding: 5px 15px; }
  .menu-item.menu-item-has-children > a {
    display: inline-block;
    width: 100%; }
  .menu-item-has-children > a span {
    display: inline-block; }
  .menu-item-has-children.active > .sub-menu {
    display: block;
    position: static; } }

.btn-search {
  margin-right: 10px;
  cursor: pointer; }
  .btn-search:hover, .btn-search:focus {
    color: #000;
    outline: none; }
    .btn-search:hover .icon-search, .btn-search:focus .icon-search {
      color: #000; }
  .btn-search .icon-search {
    color: #195020;
    font-size: 24px;
    transition: .3s; }

.menu-col {
  width: 100%; }

.menu-search-col {
  position: relative;
  margin-top: 20px;
  display: none; }
  @media screen and (min-width: 64em) {
    .menu-search-col {
      margin-top: 0; } }
  .menu-search-col .search-form {
    top: 24px;
    right: -44px;
    background-color: #195020;
    padding: 15px;
    position: absolute;
    transform: scale(0.5);
    transform-origin: center;
    transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-property: transform;
    width: 250px; }
    @media screen and (min-width: 64em) {
      .menu-search-col .search-form {
        top: 44px; } }
    .menu-search-col .search-form input[type="search"] {
      background-color: #c7cc3c;
      border: none;
      line-height: 40px;
      margin-bottom: 0;
      width: 100%;
      padding-left: 13px;
      padding-right: 13px;
      font-size: 13px;
      color: #fff; }
      .menu-search-col .search-form input[type="search"]:focus {
        outline: none; }
      .menu-search-col .search-form input[type="search"]::placeholder {
        font-size: 13px;
        color: #efefef;
        font-style: italic;
        font-family: "Gudea", sans-serif; }
    .menu-search-col .search-form:before {
      content: "";
      position: absolute;
      border-style: solid;
      border-color: #2e2e30 transparent;
      display: block;
      width: 0;
      top: -7px;
      right: 20px;
      border-width: 0 7px 7px; }
  .menu-search-col--active .search-form {
    transform: scale(1); }

li.mega-menu-wrapper {
  position: static; }

.main-menu .mega-sub-menu {
  display: none;
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  background-color: #3a3a3c;
  padding: 30px 0;
  box-shadow: inset 0 10px 5px -5px rgba(24, 24, 24, 0.3); }
  @media screen and (max-width: 1023px) {
    .main-menu .mega-sub-menu {
      position: static;
      width: 100%; }
      .main-menu .mega-sub-menu:after {
        content: "";
        display: table;
        clear: both; } }
  .main-menu .mega-sub-menu__section {
    width: 50%;
    float: left; }
    .main-menu .mega-sub-menu__section--two-thirds {
      width: 66.66%; }
      @media screen and (max-width: 480px) {
        .main-menu .mega-sub-menu__section--two-thirds .mega-sub-menu__list {
          width: 50%; } }
    .main-menu .mega-sub-menu__section--one-thirds {
      width: 33.33%; }
    .main-menu .mega-sub-menu__section--separator {
      position: relative; }
      .main-menu .mega-sub-menu__section--separator:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #4d4d4f;
        display: block;
        position: absolute;
        left: 0;
        top: 0; }
    @media screen and (max-width: 700px) {
      .main-menu .mega-sub-menu__section {
        width: 100%;
        text-align: center; } }
  .main-menu .mega-sub-menu__title {
    color: #ffffff;
    font-family: "Slabo 27px", serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
    margin-left: 25px; }
    @media screen and (max-width: 700px) {
      .main-menu .mega-sub-menu__title {
        margin-left: 0; } }
  .main-menu .mega-sub-menu__list {
    list-style: none;
    padding-right: 15px;
    margin: 0;
    width: 25%;
    float: left; }
    .main-menu .mega-sub-menu__list--two-cols {
      width: 50%; }
    @media screen and (max-width: 480px) {
      .main-menu .mega-sub-menu__list {
        width: 100%;
        text-align: center; } }
  .main-menu .mega-sub-menu__link {
    color: #b4b4b4;
    font-family: "Gudea", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    padding: 0;
    padding-left: 10px; }
    @media screen and (min-width: 1024px) {
      .main-menu .mega-sub-menu__link:hover {
        background-color: #195020; } }

.main-menu .toggle-menu-item {
  float: right;
  transform: rotate(-90deg);
  transition: 150ms ease-in;
  transition-property: transform; }

.menu-item--active.menu-item > a {
  color: #ffffff;
  background-color: #195020; }
  .menu-item--active.menu-item > a .toggle-menu-item {
    transform: rotate(-270deg); }

.sticky-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #fff; }
  @media screen and (max-width: 1024px) {
    .sticky-menu--active {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: #fff;
      width: 100%;
      overflow-y: scroll;
      height: 100%; } }

/* TILE GRID */
.tile-col {
  margin-bottom: 10px; }
  @media screen and (min-width: 40em) {
    .tile-col {
      margin-bottom: 38px; } }
  @media screen and (min-width: 40em) {
    .tile-col--style-2 {
      margin-bottom: 30px; } }

.tile {
  text-align: center;
  height: 100%;
  padding: 40px 30px 54px;
  background-color: #f8f8f8;
  position: relative; }
  .tile:hover .tile__title,
  .tile:hover .tile__icon,
  .tile:hover .tile__arrow {
    color: #195020; }
  .tile:hover .tile__icon {
    transform: scale(1.16); }
  .tile:hover .tile__arrow {
    transform: translateX(-20%); }
  .tile__icon {
    font-size: 36px;
    color: #898d98;
    transition: 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transition-property: color, transform; }
  .tile__title {
    font-family: "Gudea", sans-serif;
    color: #303133;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 8px;
    transition: 250ms ease-in;
    transition-property: color; }
  .tile__description {
    line-height: 24px;
    margin-top: 7px; }
  .tile__arrow {
    font-family: "Gudea", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #b9b9b9;
    line-height: 16px;
    z-index: 1;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    transition: 300ms ease-out;
    transition-property: transform; }
    .tile__arrow:visited {
      color: #b9b9b9; }
  .tile__link {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 5;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0; }
  .tile--style-2 {
    padding: 0;
    background-color: transparent;
    text-align: left; }
    .tile--style-2 .tile__title {
      margin-top: 23px; }

.effect-apollo > .s-back-switch.tile__img {
  height: 230px; }
  .effect-apollo > .s-back-switch.tile__img--height-2 {
    height: 170px; }

@media screen and (min-width: 64em) {
  .tile-practice-2-row {
    margin-top: 60px; } }

.our-people-wrapper {
  padding-top: 30px;
  padding-bottom: 60px; }
  @media screen and (min-width: 64em) {
    .our-people-wrapper {
      padding-bottom: 105px; } }

.team-person-wrapper {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  margin-bottom: 48px; }
  .team-person-wrapper.row {
    margin-left: 0;
    margin-right: 0; }

.team-person {
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  height: calc(100% - 20px); }
  .team-person__img > .s-back-switch {
    min-height: 182px; }
  .team-person__name {
    margin-top: 17px;
    font-family: "Gudea", sans-serif;
    font-size: 16px;
    color: #303133;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 0; }
  .team-person__position {
    color: #909090;
    font-style: italic;
    line-height: 22px;
    margin-bottom: 12px; }
  .team-person__link {
    font-family: Gudea;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    vertical-align: baseline;
    position: absolute;
    bottom: 0; }
    .team-person__link:hover:after {
      margin-left: 10px; }
    .team-person__link:after {
      content: '\ede6';
      font-family: fontello;
      font-size: 12px;
      margin-left: 5px;
      transition: 250ms ease-in;
      transition-property: margin-left; }
  .team-person--style-2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    .team-person--style-2 .team-person__img > .s-back-switch {
      min-height: 300px; }
    .team-person--style-2 .team-person__data {
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      padding: 20px;
      border-bottom: 3px solid #3a3a3c;
      cursor: pointer;
      transition: .3s; }
      .team-person--style-2 .team-person__data:hover {
        background-color: #3a3a3c; }
        .team-person--style-2 .team-person__data:hover .team-person__name,
        .team-person--style-2 .team-person__data:hover .team-person__position,
        .team-person--style-2 .team-person__data:hover .team-person__contact {
          color: #fff; }
        .team-person--style-2 .team-person__data:hover .team-person__link {
          color: #195020; }
    .team-person--style-2 .team-person__name {
      margin-top: 0;
      margin-bottom: 2px;
      font-size: 20px; }
    .team-person--style-2 .team-person__contact {
      font-size: 15px;
      color: #303133; }
    .team-person--style-2 .team-person__link {
      margin-top: 10px;
      position: static; }
    .team-person--style-2 .team-person__position {
      margin-bottom: 27px; }

@media screen and (max-width: 1024px - 1px) {
  .team-single-practice-row {
    margin-bottom: 50px; } }

/* HOVER EFFECTS */
/* Bubba */
.effect-bubba {
  position: relative; }

.effect-bubba::before,
.effect-bubba::after {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  content: '';
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s; }

.effect-bubba::before {
  border-top: 3px solid #195020;
  border-bottom: 3px solid #195020;
  transform: scale(0, 1); }

.effect-bubba::after {
  border-right: 3px solid #195020;
  border-left: 3px solid #195020;
  transform: scale(1, 0); }

.effect-bubba:hover::before,
.effect-bubba:hover::after {
  opacity: 1;
  transform: scale(1); }

/* Apollo */
.effect-apollo {
  background-color: #195020;
  position: relative;
  overflow: hidden;
  margin: 0; }
  .effect-apollo__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .effect-apollo__overlay:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      content: '';
      transition: transform 0.6s;
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -110%, 0); }
  .effect-apollo:hover > img {
    opacity: 0.6;
    transform: scale(1); }
  .effect-apollo:hover .effect-apollo__overlay:before {
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 110%, 0); }
  .effect-apollo > img {
    opacity: 1;
    transform: scale(1.06);
    position: relative;
    transition: opacity 0.35s, transform 0.35s;
    min-height: 100%;
    width: 100%;
    background-color: #FFF;
    filter: none; }

/* FORM ELEMENTS */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
select,
textarea {
  background-color: #f5f5f5;
  border-color: transparent;
  box-shadow: none;
  margin-bottom: 10px; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="tel"]:focus,
  select:focus,
  textarea:focus {
    background-color: #f5f5f5;
    box-shadow: none; }

label {
  color: #777;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; }

select {
  padding-right: 2rem;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAQCAMAAABA3o1rAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA0ppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzkyNkJFNTZCN0JEMTFFNkE2MDNGNjNGMkFCQ0M1NUUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzkyNkJFNTVCN0JEMTFFNkE2MDNGNjNGMkFCQ0M1NUUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo1YjZmNjk1OC1iN2JkLTExZTYtOTEzMy1iN2MzMWZmYjMzYTAiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo1YjZmNjk1OC1iN2JkLTExZTYtOTEzMy1iN2MzMWZmYjMzYTAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6+uV7IAAAABlBMVEW1tbUAAAAd6wLFAAAAAnRSTlP/AOW3MEoAAABHSURBVHjahMrJEQAgCMBA03/TjjcgR76b1qqomPRgDGQ+BxJfA7HvgdDPQOR3IPA34LsYcF0OeK4GHNcDv5uBz+2AdboAAwCh6ADxwANGWwAAAABJRU5ErkJggg=="); }

.search-form {
  position: relative;
  z-index: 1; }
  .search-form__submit {
    margin-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 12px;
    cursor: pointer; }

.dark-section input[type="text"],
.dark-section input[type="email"],
.dark-section input[type="password"],
.dark-section input[type="tel"],
.dark-section select,
.dark-section textarea {
  background-color: #232325;
  color: #b4b4b4; }
  .dark-section input[type="text"]:focus,
  .dark-section input[type="email"]:focus,
  .dark-section input[type="password"]:focus,
  .dark-section input[type="tel"]:focus,
  .dark-section select:focus,
  .dark-section textarea:focus {
    border: 1px solid #707071; }

.dark-section label {
  color: #b4b4b4; }

/* widget - latest post */
.widget-latest-posts ul li, .widget-latest-posts-thumb ul li {
  margin-bottom: 6px; }

@media screen and (min-width: 64em) {
  .latest-posts-wrapper {
    margin-top: 92px; } }

.latest-posts-wrapper .widget-latest-posts, .latest-posts-wrapper .widget-latest-posts-thumb {
  padding-left: 13px; }
  .latest-posts-wrapper .widget-latest-posts .widget-title, .latest-posts-wrapper .widget-latest-posts-thumb .widget-title {
    font-family: "Slabo 27px", serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;
    margin-bottom: 22px; }
  .latest-posts-wrapper .widget-latest-posts ul li, .latest-posts-wrapper .widget-latest-posts-thumb ul li {
    border-bottom: none;
    padding: 0; }
  .latest-posts-wrapper .widget-latest-posts h4, .latest-posts-wrapper .widget-latest-posts-thumb h4 {
    margin-bottom: 0; }
  .latest-posts-wrapper .widget-latest-posts-thumb {
    padding-left: 13px; }
    .latest-posts-wrapper .widget-latest-posts-thumb .widget-title {
      font-family: "Slabo 27px", serif;
      font-size: 24px;
      font-weight: 400;
      color: #000;
      margin-bottom: 25px; }
    .latest-posts-wrapper .widget-latest-posts-thumb ul li {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
      margin-bottom: 15px;
      padding-top: 5px;
      padding-bottom: 20px;
      padding-right: 15px; }
      .latest-posts-wrapper .widget-latest-posts-thumb ul li:last-child {
        border-bottom: none; }
    .latest-posts-wrapper .widget-latest-posts-thumb__thumb {
      width: 85px;
      height: 75px;
      margin-right: 15px;
      min-width: 85px; }

.latest-posts-wrapper .widget-blockquote {
  margin-top: -19px; }
  .latest-posts-wrapper .widget-blockquote .widget-title {
    display: none; }

@media screen and (min-width: 64em) {
  .latest-posts-wrapper--home-2, .latest-posts-wrapper--home-3 {
    margin-top: 68px; } }

.latest-posts-wrapper--home-2 .widget-latest-posts, .latest-posts-wrapper--home-2 .widget-latest-posts-thumb, .latest-posts-wrapper--home-3 .widget-latest-posts, .latest-posts-wrapper--home-3 .widget-latest-posts-thumb {
  padding-left: 0; }
  .latest-posts-wrapper--home-2 .widget-latest-posts--thumb, .latest-posts-wrapper--home-3 .widget-latest-posts--thumb {
    padding-left: 0; }

.latest-posts-wrapper--home-2 .widget-blockquote, .latest-posts-wrapper--home-3 .widget-blockquote {
  margin-top: 0; }

@media screen and (min-width: 64em) {
  .latest-posts-wrapper--home-3 {
    margin-bottom: 130px; } }

.dark-section .widget-latest-posts__title a {
  color: #b4b4b4; }
  .dark-section .widget-latest-posts__title a:hover {
    color: #195020; }

.dark-section .widget-latest-posts li, .dark-section .widget-latest-posts-thumb li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .dark-section .widget-latest-posts li:last-child, .dark-section .widget-latest-posts-thumb li:last-child {
    border-bottom: none; }

/* GET A QUOTE */
.get-a-quote {
  background-color: #195020;
  padding: 28px 0 30px;
  margin-top: 47px; }
  .get-a-quote .column {
    position: relative; }
  .get-a-quote__text {
    font-family: "Gudea", sans-serif;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: #fff;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center; }
    @media screen and (min-width: 40em) {
      .get-a-quote__text {
        margin-bottom: 0;
        text-align: left; } }
  .get-a-quote__link {
    background-color: #fff;
    line-height: 37px;
    padding: 2px 20px;
    color: #195020;
    z-index: 1;
    box-shadow: none; }

/* FIND PEOPLE MAP */
.find-people-map-inner {
  padding-bottom: 15px; }
  @media screen and (min-width: 40em) {
    .find-people-map-inner {
      padding-bottom: 45px; } }
  @media screen and (min-width: 64em) {
    .find-people-map-inner {
      padding-bottom: 49px; } }

.find-people-map {
  background-color: #fafafa; }
  @media screen and (min-width: 64em) {
    .find-people-map {
      margin-top: 47px; } }
  .find-people-map__img {
    width: 100%; }
  .find-people-map__list {
    list-style: none;
    margin-left: 0;
    margin-top: 1em; }
    @media screen and (max-width: 1024px) {
      .find-people-map__list {
        margin-top: 2em; } }
    .find-people-map__list li:before {
      content: none; }
  .find-people-map__link {
    color: #777;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; }
    .find-people-map__link:hover {
      color: #195020; }
  .find-people-map--big {
    margin-bottom: 135px;
    background: none; }
    @media screen and (min-width: 64em) {
      .find-people-map--big {
        margin-top: 0; } }
    @media screen and (max-width: 1024px) {
      .find-people-map--big {
        margin-bottom: 70px; } }
    .find-people-map--big .find-people-map__img {
      margin-bottom: 70px; }
      @media screen and (max-width: 1024px) {
        .find-people-map--big .find-people-map__img {
          margin-bottom: 40px; } }
    .find-people-map--big .find-people-map__list {
      padding-left: 0; }
      .find-people-map--big .find-people-map__list li {
        line-height: 22px; }

/* SERVICES LINKS */
.services-links-row {
  background-color: #e7e7e7; }
  @media screen and (min-width: 64em) {
    .services-links-row .services-links__column {
      padding-left: 2.1rem; } }

.services-links.row {
  margin-right: 0;
  margin-left: 0; }
  @media screen and (max-width: 1024px) {
    .services-links.row {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media screen and (max-width: 1024px) {
    .services-links.row > .columns {
      padding-right: 0;
      padding-left: 0; } }

.services-links__column {
  display: flex;
  align-items: center;
  background-color: #e1e1e1;
  border-bottom: 1px solid rgba(195, 195, 195, 0.5);
  padding: 30px 15px;
  transition: 0.35s ease-out 0.1s;
  transition-property: color, background-color; }
  @media screen and (min-width: 40em) {
    .services-links__column {
      justify-content: center;
      padding: 48px 0; } }
  @media screen and (min-width: 64em) {
    .services-links__column {
      border-right: 1px solid rgba(195, 195, 195, 0.5);
      justify-content: flex-start;
      border-bottom: none; }
      .services-links__column:first-child {
        border-left: 1px solid rgba(195, 195, 195, 0.5); } }
  .services-links__column:hover {
    background-color: #262729;
    color: #fff; }
    .services-links__column:hover .services-links__title,
    .services-links__column:hover .services-links__item-content,
    .services-links__column:hover a,
    .services-links__column:hover *[class^=icon-] {
      color: #fff; }
    .services-links__column:hover *[class^=icon-] {
      transform: scale(1.17); }
  .services-links__column *[class^=icon-] {
    transform: scale(1);
    transition: 0.35s ease-out 0.1s;
    transition-property: transform;
    font-size: 32px;
    color: #898d98; }
    @media screen and (min-width: 40em) {
      .services-links__column *[class^=icon-] {
        font-size: 36px; } }
    .services-links__column *[class^=icon-]:before {
      margin-right: .6em; }
  .services-links__column a {
    color: #303133;
    display: flex; }
    @media screen and (min-width: 40em) {
      .services-links__column a {
        margin-left: -30px; } }
    @media screen and (min-width: 64em) {
      .services-links__column a {
        margin-left: 0; } }
    .services-links__column a:hover {
      color: #fff; }

.services-links__title {
  font-size: 32px;
  letter-spacing: -0.025em;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 32px; }

.services-links__item {
  position: relative; }

.services-links__item-content {
  font-size: 14px;
  color: #303133;
  margin-bottom: 0;
  margin-top: 0; }

.service-item {
  margin-bottom: 40px; }
  @media screen and (min-width: 64em) {
    .service-item {
      margin-bottom: 0; } }
  .service-item__thumb {
    min-height: 260px;
    width: 100%;
    margin: 0; }
    .service-item__thumb img {
      width: 100%; }

.service-blockquote-wrapper--light-grey-bg {
  background-color: #fafafa;
  padding: 1px 0; }
  @media screen and (min-width: 64em) {
    .service-blockquote-wrapper--light-grey-bg {
      margin-top: 38px; } }

.service-blockquote-wrapper {
  margin-top: .75rem;
  margin-bottom: 3.25rem;
  max-width: 900px;
  padding: 0 15px; }
  @media screen and (min-width: 64em) {
    .service-blockquote-wrapper {
      margin-top: 3.25rem; } }
  @media screen and (min-width: 64em) {
    .service-blockquote-wrapper {
      margin-top: 4rem;
      margin-bottom: 5.9rem; } }

/* SERVICE BLOCKQUOTE */
.service-blockquote {
  text-align: center; }
  .service-blockquote__text {
    font-family: "Gudea", sans-serif;
    font-style: normal;
    margin-bottom: 24px;
    padding-top: 4px;
    text-indent: 30px; }
    @media screen and (min-width: 64em) {
      .service-blockquote__text {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.025em;
        color: #303133;
        text-indent: 0;
        line-height: 38px; } }
    .service-blockquote__text:before {
      font-family: "PT Serif", serif;
      font-style: italic;
      position: absolute;
      top: 3px;
      left: -36px;
      bottom: initial;
      line-height: 1; }
      @media screen and (min-width: 64em) {
        .service-blockquote__text:before {
          top: 6px;
          left: 5px; } }
      @media screen and (min-width: 64em) {
        .service-blockquote__text:before {
          font-size: 88px; } }
  .service-blockquote__author {
    font-size: 18px;
    font-weight: 700;
    color: #195020;
    font-family: "Gudea", sans-serif;
    line-height: 1;
    font-style: normal; }

/* FOOTER */
.main-footer {
  padding: 40px 0 10px;
  background-color: #2e2e30;
  color: #b4b4b4; }
  @media screen and (min-width: 64em) {
    .main-footer {
      padding: 75px 0 50px; } }
  .main-footer a {
    color: #ffffff; }
    .main-footer a:hover {
      color: #ffffff; }
  .main-footer .logo-socials-footer,
  .main-footer .copyright {
    margin: 0; }
    @media screen and (max-width: 639px) {
      .main-footer .logo-socials-footer,
      .main-footer .copyright {
        justify-content: center;
        text-align: center; } }
    .main-footer .logo-socials-footer > .columns,
    .main-footer .copyright > .columns {
      margin-top: 5px; }
      @media screen and (min-width: 64em) {
        .main-footer .logo-socials-footer > .columns,
        .main-footer .copyright > .columns {
          margin-top: 0; } }
  .main-footer .logo-socials-footer {
    border-top: 1px dashed rgba(255, 255, 255, 0.1);
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    margin-top: 9px;
    padding-top: 9px;
    padding-bottom: 15px; }
  .main-footer .widget-title {
    font-size: 17px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 23px;
    font-family: "Gudea", sans-serif; }
    .main-footer .widget-title--contacts {
      margin-bottom: 29px; }
  .main-footer .widget-latest-posts__list {
    margin-top: 34px; }
  .main-footer .widget-address {
    margin-bottom: 36px; }
    .main-footer .widget-address .widget-title {
      margin-bottom: 28px; }
  @media screen and (min-width: 64em) {
    .main-footer .row-widgets {
      margin-bottom: 5px; } }
  .main-footer .copyright {
    font-size: 14px;
    padding-bottom: 20px; }
    @media screen and (min-width: 64em) {
      .main-footer .copyright {
        padding-top: 45px; } }
    .main-footer .copyright a {
      font-size: 14px; }
  .main-footer .widget-subscribe .widget-title {
    margin-bottom: 30px; }
  .main-footer .socials {
    padding-top: 7px; }

/* widget subscribe */
.widget-subscribe p {
  font-size: 13px;
  color: #909090;
  line-height: 18px;
  margin-bottom: 15px; }

.widget-subscribe input {
  width: calc(100% - 40px);
  display: inline-block;
  height: 40px;
  line-height: 41px;
  font-family: "Gudea", sans-serif;
  padding-left: 15px; }
  .widget-subscribe input::-webkit-input-placeholder {
    font-style: italic;
    color: #909090;
    font-size: 13px;
    vertical-align: middle;
    line-height: 24px; }
  .widget-subscribe input:-moz-placeholder {
    font-style: italic;
    color: #909090;
    font-size: 13px;
    vertical-align: middle;
    line-height: 24px; }
  .widget-subscribe input::-moz-placeholder {
    font-style: italic;
    color: #909090;
    font-size: 13px;
    vertical-align: middle;
    line-height: 24px; }
  .widget-subscribe input:-ms-input-placeholder {
    font-style: italic;
    color: #909090;
    font-size: 13px;
    vertical-align: middle;
    line-height: 24px; }
  .widget-subscribe input:focus {
    box-shadow: none; }

.widget-subscribe__submit {
  width: 40px;
  height: 40px;
  display: inline-block;
  float: right;
  border: none;
  color: #fff;
  background-color: #195020;
  transition: all .35s ease; }
  .widget-subscribe__submit:hover {
    cursor: pointer;
    background-color: #fff;
    color: #195020; }
    .widget-subscribe__submit:hover:before {
      transform: scale(1.3); }

.dark-section .widget-subscribe input[type="text"] {
  background-color: #232325;
  color: #fff; }

/* WIDGET ADDRESS */
.widget-address {
  margin-bottom: 30px; }
  .widget-address p {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    margin-bottom: 11px; }
    .widget-address p.widget-address__info {
      font-size: 15px;
      font-weight: 400;
      color: #000;
      margin-bottom: 3px;
      line-height: 22px; }
      .widget-address p.widget-address__info:before {
        margin-left: 0;
        margin-right: 15px;
        color: #b4b4b4;
        font-size: 18px; }
  .widget-address a {
    color: #303133; }
    .widget-address a.widget-address__link {
      color: #195020;
      font-size: 15px;
      line-height: 18px; }
      .widget-address a.widget-address__link:hover {
        color: #303133; }

.dark-section .widget-address__info:before {
  display: none; }

.dark-section .widget-address p {
  color: #b4b4b4;
  margin-bottom: 5px;
  line-height: 23px; }
  .dark-section .widget-address p.widget-address__info {
    line-height: 25px;
    margin-bottom: 0; }
    .dark-section .widget-address p.widget-address__info a {
      color: #ffffff; }
      .dark-section .widget-address p.widget-address__info a:hover {
        text-decoration: underline; }

/* widget tags */
.widget_tag_cloud .tagcloud a {
  border: 1px solid #e5e5e5;
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  color: #909090;
  transition: .3s; }
  .widget_tag_cloud .tagcloud a:hover {
    background-color: #303133;
    color: #fff; }

.dark-section .tagcloud a {
  border: 1px solid #707071; }
  .dark-section .tagcloud a:hover {
    border-color: #232325;
    background-color: #232325;
    color: #fff; }

/* ACCORDION */
.accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 45px; }

.accordion-title {
  font-size: 16px;
  color: #303133;
  font-family: "Slabo 27px", serif;
  padding-left: 50px;
  padding-top: 13px;
  padding-bottom: 13px;
  border: none;
  border-top: 1px solid #303133;
  margin: 0;
  position: relative;
  cursor: pointer; }
  .accordion-title::before {
    background-color: #303133;
    left: 0;
    top: 50%;
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 30px;
    content: '\e845';
    font-family: 'fontello';
    color: #fff;
    text-align: center;
    line-height: 30px;
    transform: translateY(-50%);
    margin-top: 0; }
  .accordion-title:hover, .accordion-title:focus {
    color: #303133;
    outline: none;
    background-color: transparent; }

.accordion-content {
  border: none;
  line-height: 24px;
  color: #333;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
  transition: height .3s linear, padding-top .2s linear, padding-bottom .3s linear; }

.accordion-item {
  margin-left: 0; }
  .accordion-item:before {
    content: none; }
  .accordion-item:last-child .accordion-content {
    border-bottom: 1px solid #303133; }
  .accordion-item.active > .accordion-title::before {
    content: '\e849';
    background-color: #195020; }
  .accordion-item.active .accordion-content {
    width: initial;
    height: initial;
    padding-bottom: 2.7rem;
    padding-top: .9rem; }

/* TABS */
.tabs {
  border: none; }
  .tabs-item {
    padding: 1.5rem 0;
    display: none; }
    .tabs-item.active {
      display: block; }
    .tabs-item .ordinary-list {
      margin-top: 15px;
      padding-left: 17px; }
      .tabs-item .ordinary-list li {
        margin-bottom: 23px; }
  .tabs-header ul {
    list-style-type: none;
    display: flex;
    padding-left: 0;
    margin: 0; }
  .tabs-header li:before {
    content: none; }
  .tabs-header li > a {
    display: block;
    text-align: center;
    padding: 13px 30px;
    font-family: "Slabo 27px", serif;
    font-size: 16px;
    color: #fff;
    background-color: #303133;
    margin-right: 1px; }
    .tabs-header li > a:hover, .tabs-header li > a:focus {
      background-color: #303133;
      color: #fff; }
  .tabs-header li.active > a {
    background-color: #195020; }
    .tabs-header li.active > a:focus, .tabs-header li.active > a[aria-selected='true'] {
      color: #fff; }
  .tabs-content {
    border: none;
    border-bottom: 1px solid #303133; }
    .tabs-content p + ul {
      margin-top: -11px; }
    .tabs-content ul li {
      margin-bottom: 22px; }

.single-tabs {
  margin-top: 19px;
  margin-bottom: 80px; }

/* DROP CAPS */
.drop-caps p:first-child:first-letter {
  float: left;
  line-height: 36px;
  padding: 7px 14px;
  margin-right: 20px;
  margin-top: -4px; }

.drop-caps--style-1 {
  margin-bottom: 28px; }
  .drop-caps--style-1 p:first-child:first-letter {
    font-size: 38px;
    color: #fff;
    background-color: #195020; }

.drop-caps--style-2 p:first-child:first-letter {
  font-size: 38px;
  color: #303133;
  box-shadow: inset 0 0 0 2px #303133, 0 0 1px transparent; }

/* SEPARATORS */
.separator {
  width: 100%;
  margin: 30px 0;
  border: none; }
  @media screen and (min-width: 40em) {
    .separator {
      margin: 40px 0; } }
  .separator--dashed {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2); }
  .separator--solid {
    border-bottom: 1px solid #e5e5e5; }
  .separator--solid-black {
    border-bottom: 1px solid #303133; }
  .separator--people-page {
    margin-top: 0;
    margin-bottom: 50px; }
  .separator--m35 {
    margin: 35px 0; }
    @media screen and (min-width: 40em) {
      .separator--m35 {
        margin: 35px 0; } }
  .separator--m15 {
    margin: 15px 0; }
    @media screen and (min-width: 40em) {
      .separator--m15 {
        margin: 15px 0; } }
  .separator--mt-0 {
    margin-top: 0; }
    @media screen and (min-width: 40em) {
      .separator--mt-0 {
        margin-top: 0;
        margin-bottom: 50px; } }

/* ABOUT SECTION */
.about-wrapper {
  margin: 45px 0 40px; }
  @media screen and (min-width: 64em) {
    .about-wrapper {
      margin: 70px 0 57px; } }

.about-section__column {
  display: flex; }
  .about-section__column:hover *[class^=icon-] {
    font-size: 42px;
    margin-right: 7px; }
  .about-section__column:hover .about-section__title {
    color: #195020; }
  @media screen and (max-width: 1024px - 1px) {
    .about-section__column {
      margin-top: 40px; }
      .about-section__column:first-child {
        margin-top: 0; } }
  .about-section__column [class^='icon-'] {
    font-size: 36px;
    color: #195020;
    line-height: 1;
    margin-top: 3px;
    margin-right: 13px;
    transition: 0.1s ease-in-out; }
    .about-section__column [class^='icon-']:before {
      margin: 0; }

.about-section__title {
  margin-bottom: 13px;
  line-height: 36px; }

/* THE ALERT MESSAGE BOX */
.alert {
  padding: 21px 15px 21px 29px;
  font-size: 16px;
  color: #010101;
  font-family: "Slabo 27px", serif;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border: 2px solid rgba(225, 225, 225, 0.7); }
  .alert__title {
    position: relative; }
  .alert .closebtn {
    margin-left: 15px;
    font-size: 18px;
    color: #a9a9a9;
    line-height: 24px;
    cursor: pointer;
    transition: 0.3s; }
    .alert .closebtn:hover {
      color: black; }
  .alert--info {
    border-color: rgba(20, 179, 228, 0.7);
    background-color: #ace4f5;
    color: #14b3e4; }
    .alert--info .closebtn {
      color: #14b3e4; }
  .alert--warning {
    border-color: rgba(254, 198, 77, 0.7);
    background-color: #feebc1;
    color: #fec64d; }
    .alert--warning .closebtn {
      color: #fec64d; }
  .alert--danger {
    background-color: #fbccca;
    border-color: rgba(243, 109, 106, 0.7);
    color: #f36d6a; }
    .alert--danger .closebtn {
      color: #f36d6a; }
  .alert--success {
    background-color: #a5ecdd;
    border-color: rgba(0, 201, 159, 0.7);
    color: #00c99f; }
    .alert--success .closebtn {
      color: #00c99f; }

/* CLIENT ITEM */
.client-item-slider {
  margin-bottom: 25px;
  padding-left: 1px;
  padding-right: 1px; }
  .client-item-slider .swiper-wrapper {
    box-sizing: border-box; }

.client-item {
  border: 1px solid #f0f0f0;
  text-align: center;
  display: flex;
  min-height: 110px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color .3s; }
  .client-item:hover {
    background-color: #fbfbfb; }
  .client-item img {
    margin: auto; }

/* CONTACT FORM */
.contact-form__title {
  text-align: center; }
  @media screen and (min-width: 64em) {
    .contact-form__title {
      margin-bottom: 41px; } }

.contact-form textarea {
  height: calc(100% - 30px);
  min-height: 120px; }

.contact-form button[type="submit"] {
  margin-top: 13px; }
  .contact-form button[type="submit"]:focus {
    outline: none; }

.ajax-loader:before,
.ajax-loader:after,
.ajax-loader {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out; }

.ajax-loader {
  display: none;
  font-size: 10px;
  margin: -40px auto 0;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s; }

.ajax-loader:before {
  left: -3.5em;
  animation-delay: -0.32s; }

.ajax-loader:after {
  left: 3.5em; }

.ajax-loader:before,
.ajax-loader:after {
  content: '';
  position: absolute;
  top: 0; }

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #ffffff; }
  40% {
    box-shadow: 0 2.5em 0 0 #ffffff; } }

.ajax-result {
  clear: both;
  margin-top: 20px;
  font-weight: 700; }

.ajax-result .success,
.ajax-result .error {
  display: none;
  padding: 10px;
  border: 1px solid green;
  font-size: 14px;
  color: green; }

.ajax-result .error {
  color: red;
  background: red; }

/* GALLERY */
.gallery-title {
  text-align: center; }

.filter-wrap {
  text-align: center;
  margin-bottom: 30px; }
  @media screen and (min-width: 64em) {
    .filter-wrap {
      margin-bottom: 55px; } }
  .filter-wrap .btn-filter {
    font-family: "Gudea", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #303133;
    cursor: pointer;
    padding: 0 13px; }
    .filter-wrap .btn-filter.active {
      color: #195020; }
    .filter-wrap .btn-filter:focus {
      outline: none; }

.isotope-container {
  display: flex; }
  .isotope-container .grid-sizer,
  .isotope-container .gallery-item {
    width: 100%; }
    @media screen and (min-width: 40em) {
      .isotope-container .grid-sizer,
      .isotope-container .gallery-item {
        width: calc(50% - 45px/2); } }
    @media screen and (min-width: 64em) {
      .isotope-container .grid-sizer,
      .isotope-container .gallery-item {
        width: calc(33.3333333% - 90px/3); } }
  .isotope-container .gutter-sizer {
    width: 45px; }
  .isotope-container .gallery-item {
    margin: 0;
    margin-bottom: 25px; }
    .isotope-container .gallery-item:last-child {
      margin-bottom: 0; }
    .isotope-container .gallery-item.effect-bubba {
      margin-bottom: 45px; }
      .isotope-container .gallery-item.effect-bubba:before, .isotope-container .gallery-item.effect-bubba:after {
        display: none; }
      .isotope-container .gallery-item.effect-bubba .caption {
        display: flex; }
        .isotope-container .gallery-item.effect-bubba .caption:before, .isotope-container .gallery-item.effect-bubba .caption:after {
          position: absolute;
          top: 30px;
          right: 30px;
          bottom: 30px;
          left: 30px;
          content: '';
          opacity: 0;
          transition: opacity 0.35s, transform 0.35s; }
        .isotope-container .gallery-item.effect-bubba .caption:before {
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          transform: scale(0, 1); }
        .isotope-container .gallery-item.effect-bubba .caption:after {
          border-right: 1px solid #fff;
          border-left: 1px solid #fff;
          transform: scale(1, 0); }
      .isotope-container .gallery-item.effect-bubba:hover .caption {
        background-color: rgba(0, 0, 0, 0.5); }
        .isotope-container .gallery-item.effect-bubba:hover .caption:before, .isotope-container .gallery-item.effect-bubba:hover .caption:after {
          opacity: 1;
          transform: scale(1); }
      .isotope-container .gallery-item.effect-bubba:hover .gallery-item__title {
        opacity: 1; }
      .isotope-container .gallery-item.effect-bubba .gallery-item__title {
        text-align: center;
        margin: auto;
        opacity: 0;
        color: #fff;
        transition: opacity 0.35s; }
    .isotope-container .gallery-item > a:focus {
      outline: none; }
    .isotope-container .gallery-item__img {
      height: 260px; }
    .isotope-container .gallery-item__title {
      margin-top: 21px; }
    .isotope-container .gallery-item .caption {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent;
      transition: background-color 0.35s; }
  .isotope-container--4cols .grid-sizer,
  .isotope-container--4cols .gallery-item {
    width: 100%; }
    @media screen and (min-width: 64em) {
      .isotope-container--4cols .grid-sizer,
      .isotope-container--4cols .gallery-item {
        width: calc(25% - 135px/4); } }
  .isotope-container--4cols .gallery-item__img {
    height: 190px; }
  .isotope-container--4cols .gallery-item.effect-bubba .caption:before, .isotope-container--4cols .gallery-item.effect-bubba .caption:after {
    top: 19px;
    right: 19px;
    bottom: 19px;
    left: 19px; }

.gallery-container {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
  padding-bottom: 20px; }
  @media screen and (min-width: 64em) {
    .gallery-container {
      padding-bottom: 51px;
      margin-bottom: 82px; } }
  .gallery-container.last-child {
    border: none; }
    @media screen and (max-width: 1024px - 1px) {
      .gallery-container.last-child {
        margin-bottom: 0;
        padding-bottom: 0; } }

/* widget twitter */
.widget-twitter .widget-title {
  margin-bottom: 29px; }

.widget-twitter__data {
  padding-left: 28px;
  position: relative; }
  .widget-twitter__data:before {
    content: '\ea17';
    font-family: "fontello";
    font-size: 18px;
    color: #47c0d8;
    position: absolute;
    left: 0; }
  .widget-twitter__data p, .widget-twitter__data a {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0; }

.widget-twitter__date {
  color: #303133; }

.dark-section .widget-twitter p {
  color: #b4b4b4; }

.dark-section .widget-twitter__date {
  color: #909090; }

/* widget - latest post with thumbnails */
.widget-latest-posts-thumb ul li {
  display: flex;
  padding-bottom: 5px;
  margin-bottom: 15px; }

.widget-latest-posts-thumb__thumb {
  width: 45px;
  height: 40px;
  min-width: 45px;
  margin-right: 15px; }
  .widget-latest-posts-thumb__thumb > .s-back-switch {
    height: 100%; }

.widget-latest-posts-thumb__item-meta {
  margin-top: -9px; }

.dark-section .widget-latest-posts-thumb__title a {
  color: #b4b4b4; }

.dark-section .widget-latest-posts-thumb li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .dark-section .widget-latest-posts-thumb li:last-child {
    border-bottom: none; }

.dark-section .widget-latest-posts-thumb ul {
  margin-top: 32px; }

.dark-section .widget-latest-posts-thumb .widget__date:hover {
  color: #fff; }

/* widget blockquote */
.widget-blockquote .blockquote,
.widget-blockquote blockquote {
  margin-top: 10px;
  padding: 25px 20px 40px 30px; }

.dark-section .blockquote,
.dark-section blockquote {
  background-color: #232325;
  color: #fff; }

.dark-section .blockquote--style-1:after {
  border-color: #1c1c1e transparent transparent #1c1c1e; }

/* OUR LOCATION */
.our-location__map {
  height: 220px;
  margin: 28px 0 24px; }

.experience-location {
  margin-top: 50px; }
  @media screen and (min-width: 64em) {
    .experience-location {
      margin-top: 91px; } }
  .experience-location__title {
    margin-bottom: 22px; }
    @media screen and (max-width: 639px) {
      .experience-location__title {
        text-align: center; } }
  .experience-location .our-location {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 30px; }
    @media screen and (min-width: 40em) {
      .experience-location .our-location {
        margin-bottom: 0;
        margin-top: 0;
        text-align: left; } }
  .experience-location p {
    margin-bottom: 0; }

/* NEWS */
.post {
  width: 100%;
  margin-bottom: 45px;
  padding-bottom: 45px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 64em) {
    .post {
      margin-bottom: 50px; }
      .post:not(.single-post):first-child {
        margin-bottom: 40px; } }
  .post:not(.single-post):last-child {
    border: none;
    padding-bottom: 0; }
  .post__thumb {
    margin-bottom: 31px;
    margin-top: 7px; }
    @media screen and (min-width: 64em) {
      .post__thumb--single-practice {
        height: 520px;
        margin-bottom: 37px; } }
  .post__date {
    display: inline-flex;
    align-items: center; }
    .post__date a {
      font-size: 13px;
      color: #909090; }
      .post__date a:hover {
        color: #000; }
    .post__date:after {
      content: '\e941';
      font-family: fontello;
      font-size: 5px;
      margin-left: 7px;
      margin-right: 2px; }
  .post__comments {
    font-size: 13px;
    color: #909090; }
  .post__title {
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 26px; }
  .post__footer {
    margin-top: 17px; }
  .post__share {
    font-size: 16px;
    color: #303133;
    margin-top: 33px; }
    .post__share .socials {
      margin-left: 5px;
      display: inline-block; }
  .post__tags a {
    font-size: 13px;
    color: #909090; }
    .post__tags a:hover {
      color: #303133; }
    .post__tags a:after {
      content: ","; }
    .post__tags a:last-child:after {
      content: none; }
  .post__likes {
    font-size: 13px;
    color: #909090; }
    .post__likes:hover {
      color: #303133; }
  .post__pager {
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    margin-top: 51px;
    margin-left: 0;
    margin-right: 0;
    padding-top: 24px;
    font-weight: 700; }
    .post__pager--prev:before {
      content: '\ede5';
      font-family: fontello;
      font-size: 12px;
      margin-right: 5px;
      transition: margin-right 250ms ease-in; }
    .post__pager--prev:after {
      content: none; }
    .post__pager--prev:hover:before {
      margin-right: 10px; }
  .post__excerpt p {
    margin-bottom: 15px; }
  .post__content blockquote,
  .post__content .blockquote {
    margin-bottom: 28px; }
  .post--type-quote .post__header {
    margin-top: -9px;
    margin-bottom: 7px; }
  .post.single-post {
    margin-bottom: 0;
    padding-bottom: 50px; }
    .post.single-post .post__footer {
      margin-top: 33px; }
  .post blockquote,
  .post .blockquote {
    margin-top: 0;
    padding-left: 0; }
    .post blockquote:before,
    .post .blockquote:before {
      left: -5px; }
    .post blockquote__author-data,
    .post .blockquote__author-data {
      padding-left: 5px; }

.post-author,
.post-comment,
.post-comment--children {
  background-color: #fafafa;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 0;
  margin-right: 0; }
  .post-author__photo > .s-back-switch,
  .post-comment__photo > .s-back-switch {
    height: 100%; }
  .post-author__column,
  .post-comment__column {
    padding-right: 0; }

.post-author {
  margin-top: 53px;
  padding-bottom: 30px; }
  .post-author__photo {
    width: 120px;
    height: 130px; }
  .post-author p {
    margin-bottom: 18px; }

h1.post-author__title, h1.post-comment__name, h2.post-author__title, h2.post-comment__name, h3.post-author__title, h3.post-comment__name, h4.post-author__title, h4.post-comment__name, h5.post-author__title, h5.post-comment__name, h6.post-author__title, h6.post-comment__name {
  margin-top: 4px; }

.comments {
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  padding-top: 35px; }
  .comments__title {
    margin-bottom: 25px; }


.post-comment,
.post-comment--children {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  .post-comment__photo {
    width: 80px;
    height: 80px; }
  .post-comment__name {
    margin-bottom: -4px; }
  .post-comment time, .post-comment--children time {
    display: block;
    margin-bottom: 20px; }
  .post-comment .reply-link, .post-comment--children .reply-link {
    position: absolute;
    right: 22px;
    top: 32px;
    color: #303133; }

.row.post-author, .row.post-comment, .row.post-comment--children, .row.post__pager {
  margin-left: 0;
  margin-right: 0; }

.row.post-comment--children {
  margin-left: 98px; }

.page-heading {
  background: #195020 url("../Images/tapete.jpg");
  height: 150px;
  position: relative;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 1024px) {
    .page-heading {
      max-height: 150px; } }
  .page-heading__title {
    color: white;
    font-size: 40px;
    position: relative;
    margin: auto;
    z-index: 1; }
  .page-heading--height-2 {
    height: 250px; }
  .page-heading--height-3 {
    height: 350px; }
  .page-heading--no-img {
    background-color: #3a3a3c; }

h1.page-heading__title, h2.page-heading__title, h3.page-heading__title, h4.page-heading__title, h5.page-heading__title, h6.page-heading__title {
  margin-top: auto; }

/* SINGLE PEOPLE */
.single-people__img {
  max-width: 300px;
  margin: 0 auto 15px;
  height: 300px; }
  @media screen and (min-width: 64em) {
    .single-people__img {
      margin-bottom: 0; } }
  .single-people__img img {
    width: 100%; }

.single-people__title {
  color: #195020;
  margin-bottom: 0; }

.single-people__text, .single-people__position {
  color: #909090;
  font-style: italic;
  font-family: "Gudea", sans-serif;
  line-height: 18px; }

.single-people__position {
  margin-bottom: 3px;
  font-size: 18px; }

.single-people__city {
  margin-bottom: 22px; }

.single-people__languages {
  margin-bottom: 0; }

.single-people .widget-address {
  margin-bottom: 35px; }

h4.single-people__city {
  margin-top: 0; }

h1.single-people-content__title, h2.single-people-content__title, h3.single-people-content__title, h4.single-people-content__title, h5.single-people-content__title, h6.single-people-content__title {
  margin-top: 10px; }
  @media screen and (min-width: 64em) {
    h1.single-people-content__title, h2.single-people-content__title, h3.single-people-content__title, h4.single-people-content__title, h5.single-people-content__title, h6.single-people-content__title {
      margin-top: 22px; } }

/* VIDEO BANNER */
.play-btn {
  width: 82px;
  height: 82px;
  border: 2px solid #fff;
  font-size: 22px;
  background-color: transparent;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;
  justify-content: center;
  position: relative;
  align-items: center;
  vertical-align: middle;
  stroke: none;
  display: inline-flex; }
  .play-btn__svg {
    pointer-events: none;
    display: block;
    width: 100%;
    height: 100%; }

.video-banner {
  position: relative;
  display: flex;
  max-height: 460px;
  min-height: 300px;
  height: 100%; }
  .video-banner .video-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none; }
    .video-banner .video-popup.active {
      display: block; }
      .video-banner .video-popup.active .close-btn {
        display: block;
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 9999;
        width: 40px;
        height: 40px;
        cursor: pointer;
        opacity: 1;
        transition: all 0.5s ease; }
        .video-banner .video-popup.active .close-btn:hover {
          transform: rotate(90deg);
          opacity: 0.7;
          transition: all 0.5s ease; }
        .video-banner .video-popup.active .close-btn:before, .video-banner .video-popup.active .close-btn:after {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          width: 30px;
          height: 2px;
          background: #fff; }
        .video-banner .video-popup.active .close-btn:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        .video-banner .video-popup.active .close-btn:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
    .video-banner .video-popup .close-btn {
      display: none; }
    .video-banner .video-popup iframe {
      width: 100%;
      height: 100%; }

/* ABOUT ARTICLE */
.about-article-fluid {
  background-color: rgba(248, 248, 248, 0.5);
  padding: 20px 0 50px; }
  @media screen and (min-width: 40em) {
    .about-article-fluid {
      margin: 40px 0 30px; } }
  @media screen and (min-width: 64em) {
    .about-article-fluid {
      padding: 80px 0 100px;
      margin: 70px 0 5px; } }

.about-article__title {
  margin-bottom: 28px;
  line-height: 1; }

.about-article__section {
  margin-top: 26px; }
  .about-article__section-title {
    margin-bottom: 13px; }
  .about-article__section p {
    margin-bottom: 25px; }

.our-people-wrapper.our-people-wrapper--about {
  padding-top: 0;
  padding-bottom: 0; }
  .our-people-wrapper.our-people-wrapper--about .block-title {
    margin-bottom: 37px; }

/* SINGLE LOCATION */
.single-location__map {
  height: 450px; }

.single-location__title {
  font-size: 32px;
  color: #195020;
  line-height: 36px; }

.single-location__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #303133;
  margin: 16px 0 29px;
  display: block; }

.locations__map {
  height: 710px; }

.locations__title {
  font-size: 32px;
  color: #195020;
  line-height: 36px; }

.locations__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #303133;
  margin: 16px 0 29px;
  display: block; }

.home-find-people__map {
  height: 474px; }

.home-find-people__title {
  font-size: 32px;
  color: #195020;
  line-height: 36px; }

.home-find-people__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #303133;
  margin: 16px 0 29px;
  display: block; }

h1.single-location__title, h2.single-location__title, h3.single-location__title, h4.single-location__title, h5.single-location__title, h6.single-location__title {
  margin-top: 30px; }
  @media screen and (min-width: 40em) {
    h1.single-location__title, h2.single-location__title, h3.single-location__title, h4.single-location__title, h5.single-location__title, h6.single-location__title {
      margin-top: 5px; } }

.row.location-areas {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  padding-bottom: 17px;
  padding-top: 35px;
  margin: 30px 0 37px; }

/* FAQ */
.faq-item {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  margin-bottom: 51px; }
  .faq-item:first-child h4 {
    margin-top: 5px; }
  .faq-item__question {
    padding-left: 70px;
    position: relative;
    min-height: 50px;
    margin-bottom: 15px; }
  .faq-item__answer {
    padding-left: 70px;
    position: relative;
    min-height: 50px;
    margin-bottom: 15px; }
  .faq-item__letter {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: #303133;
    color: #fff;
    font-size: 38px;
    font-weight: 400;
    line-height: 50px;
    text-align: center; }
    .faq-item__letter--red {
      background-color: #195020; }

@media screen and (min-width: 40em) {
  .faq-items {
    margin-bottom: 100px; } }

@media screen and (min-width: 64em) {
  .faq-items {
    margin-bottom: 205px; } }

/* COMMENT FORM */
.comment-form {
  margin-top: 18px; }
  @media screen and (max-width: 639px) {
    .comment-form {
      margin-bottom: 50px; } }
  .comment-form input {
    max-width: 450px; }
  .comment-form textarea {
    height: 240px;
    resize: none; }
  .comment-form__title {
    margin-bottom: 40px; }
  .comment-form button[type="submit"] {
    margin-top: 10px; }

/* CONTACT PAGE */
.contacts-section {
  background-color: #fafafa;
  padding-bottom: 20px;
  margin-bottom: 50px; }
  @media screen and (min-width: 64em) {
    .contacts-section {
      padding-bottom: 45px;
      margin-bottom: 90px; } }
  .contacts-section__title {
    text-align: center; }
    @media screen and (min-width: 64em) {
      .contacts-section__title {
        margin-bottom: 38px; } }

/* CARRERS */
@media screen and (min-width: 64em) {
  .careers-thumb {
    margin-bottom: 37px;
    height: 520px; } }

.careers-table tr:hover a {
  color: #fff; }

.careers-table td a {
  color: #303133;
  transition: none; }
  .careers-table td a:hover {
    color: #fff; }

.single-vacancy p + ul {
  margin-top: -18px; }

/* BLOG PAGINATION */
.pagination {
  margin-bottom: 50px; }
  .pagination .page-numbers {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    text-align: center;
    line-height: 30px; }
    .pagination .page-numbers:last-child {
      margin-right: 0; }
    .pagination .page-numbers.current {
      background-color: #195020;
      color: #fff; }
  .pagination .nav-links {
    display: flex;
    justify-content: center; }
