/* VIDEO BANNER */
.play-btn {
	width: 82px;
	height: 82px;
	border: 2px solid #fff;
	font-size: 22px;
	background-color: transparent;
	border-radius: 50%;
	margin: auto;
	cursor: pointer;
	justify-content: center;
	position: relative;
	align-items: center;
	vertical-align: middle;
	stroke: none;
	display: inline-flex;
	&__svg {
		pointer-events: none;
		display: block;
		width: 100%;
		height: 100%;
	}
}

.video-banner {
	position: relative;
	display: flex;
	max-height: 460px;
	min-height: 300px;
	height: 100%;
	.video-popup {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		&.active {
			display: block;
			.close-btn {
				display: block;
				position: absolute;
				top: 30px;
				right: 30px;
				z-index: 9999;
				width: 40px;
				height: 40px;
				cursor: pointer;
				opacity: 1;
				transition: all 0.5s ease;
				&:hover {
					transform: rotate(90deg);
					opacity: 0.7;
					transition: all 0.5s ease;
				}
				&:before,
				&:after {
					position: absolute;
					content: '';
					top: 50%;
					left: 50%;
					width: 30px;
					height: 2px;
					background: #fff;
				}
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
		.close-btn {
			display: none;
		}
		iframe {
			width: 100%;
			height: 100%;
		}
	}
}