.our-people-wrapper {
  padding-top: 30px;
  padding-bottom: 60px;
  @include large-size {
    padding-bottom: 105px;
  }
}

.team-person-wrapper {
  border-bottom: 1px dashed rgba(0,0,0,.2);
  margin-bottom: 48px;
  &.row {
    margin-left: 0;
    margin-right: 0;
  }
}

.team-person {
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  height: calc(100% - 20px);
  &__img {
    > .s-back-switch {
      min-height: 182px;
    }
  }
  &__name {
    margin-top: 17px;
    font-family: $primary-font-family;
    font-size: 16px;
    color: #303133;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 0;
  }
  &__position {
    color: #909090;
    font-style: italic;
    line-height: 22px;
    margin-bottom: 12px;
  }
  &__link {
    font-family: Gudea;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    vertical-align: baseline;
    position: absolute;
    bottom: 0;
    &:hover {
      &:after {
        margin-left: 10px;
      }
    }
    &:after {
      content: '\ede6';
      font-family: fontello;
      font-size: 12px;
      margin-left: 5px;
      transition: 250ms ease-in;
      transition-property: margin-left;
    }
  }
  &--style-2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .team-person {
      &__img {
        > .s-back-switch {
          min-height: 300px;
        }
      }
      &__data {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        padding: 20px;
        border-bottom: 3px solid #3a3a3c;
        cursor: pointer;
        transition: .3s;
        &:hover {
          background-color: #3a3a3c;
          .team-person__name,
          .team-person__position,
          .team-person__contact {
            color: #fff;
          }
          .team-person__link {
            color: $main-theme-color;
          }
        }
      }
      &__name {
        margin-top: 0;
        margin-bottom: 2px;
        font-size: 20px;
      }
      &__contact {
        @include font(15px,$content-color-minor);
      }
      &__link {
        margin-top: 10px;
        position: static;
      }
      &__position {
        margin-bottom: 27px;
      }
    }
  }
}

.team-single-practice-row {
  @include large-size-max {
    margin-bottom: 50px;
  }
}