/* widget subscribe */
.widget-subscribe {
  p {
    @include font(13px, #909090);
    line-height: 18px;
    margin-bottom: 15px;
  }
  input  {
    width: calc(100% - 40px);
    display: inline-block;
    height: 40px;
    line-height: 41px;
    font-family: $primary-font-family;
    padding-left: 15px;
    @include placeholder {
      font-style: italic;
      color: #909090;
      font-size: 13px;
      vertical-align: middle;
      line-height: 24px;
    }
    &:focus {
      box-shadow: none;
    }
  }
  &__submit {
    width: 40px;
    height: 40px;
    display: inline-block;
    float: right;
    border: none;
    color: #fff;
    background-color: $main-theme-color;
    transition: all .35s ease;
    &:hover {
      cursor: pointer;
      background-color: #fff;
      color: $main-theme-color;
      &:before {
        transform: scale(1.3);
      }
    }
  }
}

.dark-section {
  .widget-subscribe {
    input[type="text"] {
      background-color: #232325;
      color: #fff;
    }
  }
}
