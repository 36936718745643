/* CONTACT FORM */
.contact-form {
	&__title {
		text-align: center;
		@include large-size {
			margin-bottom: 41px;
		}
	}
	textarea {
		height: calc(100% - 30px);
		min-height: 120px;
	}
	button[type="submit"] {
		margin-top: 13px;
		&:focus {
			outline: none;
		}
	}
}

.ajax-loader:before,
.ajax-loader:after,
.ajax-loader {
	border-radius: 50%;
	width: 1.5em;
	height: 1.5em;
	animation-fill-mode: both;
	animation: load7 1.8s infinite ease-in-out;
}
.ajax-loader {
	display: none;
	font-size: 10px;
	margin: -40px auto 0;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	animation-delay: -0.16s;
}
.ajax-loader:before {
	left: -3.5em;
	animation-delay: -0.32s;
}
.ajax-loader:after {
	left: 3.5em;
}
.ajax-loader:before,
.ajax-loader:after {
	content: '';
	position: absolute;
	top: 0;
}
@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em #ffffff;
	}
	40% {
		box-shadow: 0 2.5em 0 0 #ffffff;
	}
}

.ajax-result{
	clear: both;
	margin-top: 20px;
	font-weight: 700;
}
.ajax-result .success,
.ajax-result .error{
	display: none;
	padding: 10px;
	border: 1px solid green;
	font-size: 14px;
	color: green;
}

.ajax-result .error{
	color: red;
	background: red;
}