/* NEWS */
.post {
	width: 100%;
	margin-bottom: 45px;
	padding-bottom: 45px;
	border-bottom: 1px dashed rgba(0,0,0,.2);
	@include large-size {
		margin-bottom: 50px;
		&:not(.single-post):first-child {
			margin-bottom: 40px;
		}
	}
	&:not(.single-post):last-child {
		border: none;
		padding-bottom: 0;
	}
	&__thumb {
		margin-bottom: 31px;
		margin-top: 7px;
		&--single-practice {
			@include large-size {
				height: 520px;
				margin-bottom: 37px;
			}
		}
	}
	&__date {
		display: inline-flex;
		align-items: center;
		a {
			@include font(13px,#909090);
			&:hover {
				color: #000;
			}
		}
		&:after {
			content: '\e941';
			font-family: fontello;
			font-size: 5px;
			margin-left: 7px;
			margin-right: 2px;
		}
	}
	&__comments {
		@include font(13px,#909090);
	}
	&__title {
		line-height: 36px;
		margin-top: 0;
		margin-bottom: 26px;
	}
	&__footer {
		margin-top: 17px;
	}
	&__share {
		@include font(16px, $content-color-minor);
		margin-top: 33px;
		.socials {
			margin-left: 5px;
			display: inline-block;
		}
	}
	&__tags {
		a {
			@include font(13px, #909090);
			&:hover {
				color: $content-color-minor;
			}
			&:after {
				content: ",";
			}
			&:last-child {
				&:after {
					content: none;
				}
			}
		}
	}
	&__likes {
		@include font(13px, #909090);
		&:hover {
			color: $content-color-minor;
		}
	}
	&__pager {
		border-top: 1px dashed rgba(0,0,0,.2);
		margin-top: 51px;
		margin-left: 0;
		margin-right: 0;
		padding-top: 24px;
		font-weight: 700;
		&--prev {
			&:before {
				content: '\ede5';
				font-family: fontello;
				font-size: 12px;
				margin-right: 5px;
				transition: margin-right 250ms ease-in;
			}
			&:after {
				content: none;
			}
			&:hover {
				&:before {
					margin-right: 10px;
				}
			}
		}
	}
	&__excerpt {
		p {
			margin-bottom: 15px;
		}
	}
	&__content {
		blockquote,
		.blockquote {
			margin-bottom: 28px;
		}
	}
	&--type-quote {
		.post {
			&__header {
				margin-top: -9px;
				margin-bottom: 7px;
			}
		}
	}
	&.single-post {
		margin-bottom: 0;
		padding-bottom: 50px;
		.post {
			&__footer{
				margin-top: 33px;
			}
		}
	}
	blockquote,
	.blockquote {
		margin-top: 0;
		padding-left: 0;
		&:before {
			left: -5px;
		}
		&__author-data {
			padding-left: 5px;
		}
	}
}

.post-author,
.post-comment {
	background-color: #fafafa;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-left: 0;
	margin-right: 0;
	&__photo {
		> .s-back-switch {
			height: 100%;
		}
	}
	&__column {
		padding-right: 0;
	}
}

.post-author {
	margin-top: 53px;
	padding-bottom: 30px;
	&__photo {
		width: 120px;
		height: 130px;
	}
	p {
		margin-bottom: 18px;
	}
}

h1, h2, h3, h4, h5, h6 {
	&.post-author__title,
	&.post-comment__name {
		margin-top: 4px;
	}
}

.comments {
	border-top: 1px dashed rgba(0,0,0,.2);
	padding-top: 35px;
	&__title {
		margin-bottom: 25px;
	}
}

.post-comment {
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 20px;
	&__photo {
		width: 80px;
		height: 80px;
	}
	&__name {
		margin-bottom: -4px;
	}
	time {
		display: block;
		margin-bottom: 20px;
	}
	.reply-link {
		position: absolute;
		right: 22px;
		top: 32px;
		color: $content-color-minor;
	}
	&--children {
		@extend .post-comment;
	}
}

.row {
	&.post-author,
	&.post-comment,
	&.post__pager {
		margin-left: 0;
		margin-right: 0;
	}
	&.post-comment--children {
		margin-left: 98px;
	}
}