/* HOVER EFFECTS */
/* Bubba */
.effect-bubba {
	position: relative;
}
.effect-bubba::before,
.effect-bubba::after {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	content: '';
	opacity: 0;
	transition: opacity 0.35s, transform 0.35s;
}

.effect-bubba::before {
	border-top: 3px solid $main-theme-color;
	border-bottom: 3px solid $main-theme-color;
	transform: scale(0,1);
}

.effect-bubba::after {
	border-right: 3px solid $main-theme-color;
	border-left: 3px solid $main-theme-color;
	transform: scale(1,0);
}

.effect-bubba:hover::before,
.effect-bubba:hover::after {
	opacity: 1;
	transform: scale(1);
}

/* Apollo */
.effect-apollo {
	background-color: $main-theme-color;
	position: relative;
	overflow: hidden;
	margin: 0;
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(255,255,255,0.5);
			content: '';
			transition: transform 0.6s;
			transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-110%,0);
		}
	}
	&:hover {
		> img {
			opacity: 0.6;
			transform: scale(1);
		}
		.effect-apollo__overlay:before {
			transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,110%,0);
		}
	}
	> img {
		opacity: 1;
		transform: scale(1.06);
		position: relative;
		transition: opacity 0.35s, transform 0.35s;
		min-height: 100%;
		width: 100%;
		background-color: #FFF;
		filter: none;
	}
}