/* SEPARATORS */
.separator {
	width: 100%;
	margin: 30px 0;
	@include medium-size {
		margin: 40px 0;
	}
	border: none;
	&--dashed {
		border-bottom: 1px dashed rgba(0,0,0,.2);
	}
	&--solid {
		border-bottom: 1px solid #e5e5e5;
	}
	&--solid-black {
		border-bottom: 1px solid $content-color-minor;
	}
	&--people-page {
		margin-top: 0;
		margin-bottom: 50px;
	}
	&--m35 {
		margin: 35px 0;
		@include medium-size {
			margin: 35px 0;
		}
	}
	&--m15 {
		margin: 15px 0;
		@include medium-size {
			margin: 15px 0;
		}
	}
	&--mt-0 {
		margin-top: 0;
		@include medium-size {
			margin-top: 0;
			margin-bottom: 50px;
		}
	}
}