/* FAQ */
.faq-item {
  border-bottom: 1px dashed rgba(0,0,0,.2);
  padding-bottom: 20px;
  margin-bottom: 51px;
  &:first-child {
    h4 {
      margin-top: 5px;
    }
  }
  &__question {
    padding-left: 70px;
    position: relative;
    min-height: 50px;
    margin-bottom: 15px;
  }
  &__answer {
    padding-left: 70px;
    position: relative;
    min-height: 50px;
    margin-bottom: 15px;
  }
  &__letter {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: $content-color-minor;
    color: #fff;
    font-size: 38px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    &--red {
      background-color: $main-theme-color;
    }
  }
}

.faq-items {
  @include medium-size {
    margin-bottom: 100px;
  }
  @include large-size {
    margin-bottom: 205px;
  }
}