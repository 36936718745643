/* MAIN NAVIGATION AND MENU */
.main-navigation {
	position: relative;
	min-height: 40px;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: stretch;
	@include large-size {
		flex-direction: row;
		align-items: center;
	}
	@media screen and (max-width: 1024px) {
		&.active {
			background-color: #fff;
			z-index: 2;
		}
	}
	&--home-2 {
		@include small-size {
			flex-direction: column;
			justify-content: space-between;
		}
		@include medium-size {
			flex-direction: column;
			justify-content: space-between;
		}
		@include large-size {
			flex-direction: row;
		}
		.menu-search-row {
			padding: 0;
		}
		.main-menu {
			justify-content: flex-end;
			.sub-menu,
			.mega-sub-menu {
				margin-top: 19px;
			}
		}
		.menu-search-col .search-form {
			top: 63px;
		}
	}
	&.row-menu {
		&--home-2 {
			@include small-size {
				flex-direction: column;
				padding: 15px 0;
			}
			@include medium-size {
				flex-direction: column;
				padding: 15px 0;
			}
			@media screen and (min-width: 1200px) {
				flex-direction: row;
				padding: 19px 0;
			}
			.logo {
				text-align: center;
				@media screen and (max-width: 1199px) {
					padding: 15px 0;
				}
			}
		}
	}
}

.logo {
	img {
		margin-bottom: 0;
		width: 3rem;
	}
	a {
		color: #262626;
		font-family: Cabin, Arial, sans-serif;
		font-size: 34px;
		font-weight: 600;
	}
}

.main-menu {
	padding: 0;
	margin: 0;
	align-items: center;
	display: flex;
	list-style-type: none;
	flex-wrap: wrap;
	li {
		position: relative;
		&:before {
			content: none;
		}
		&:hover {
			> a {
				@include large-size {
					color: $vav-l1-txt-color-hover;
					background-color: $main-theme-color;
				}
			}
		}
		a {
			position: relative;
			display: flex;
			flex-flow: row nowrap;
		}
	}
	> li {
		position: relative;
		flex: 0 0 auto;
		&:last-child {
			> a {
				&:after {
					background-color: transparent;
				}
			}
		}
		> a {
			@include font($vav-l1-txt-size, $vav-l1-txt-color);
			font-family: $vav-l1-font-family;
			line-height: 1;
			position: relative;
			@media (min-width: 64em) {
				&:after {
					content: '';
					position: absolute;
					right: -1px;
					top: 23px;
					height: 15px;
					width: 1px;
					background-color: #d5d5d6;
				}
			}
			&:hover {
				@media screen and (min-width: 1024px) {
					color: $vav-l1-txt-color-hover;
					background-color: $main-theme-color;
				}
				&:after {
					background-color: transparent;
				}
			}
		}
		a {
			transition: .2s;
			padding: 1.4rem;
		}
		&.current-menu-parent,
		&.current-menu-item {
			> a {
				color: $vav-l1-txt-color-hover;
				background-color: $main-theme-color;
				&:after {
					background-color: transparent;
				}
			}
		}
	}
	/* Active menu items */
	.current-menu-parent,
	.current-menu-item,
	.sub-menu .menu-item.current-menu-item {
		&:after {
			background-color: transparent;
		}
		> a {
			color: $vav-l1-txt-color-hover;
			background-color: $main-theme-color;
		}
	}
	.sub-menu {
		display: none;
		padding: 0;
		position: absolute;
		white-space: nowrap;
		background-color: $vav-sl-bg-color;
		margin: 0;
		box-shadow: inset 0 10px 5px -5px rgba(24,24,24,.3);
		min-width: 280px;
		.menu-item {
			a {
				@include font(14px, $vav-sl-txt-color);
				padding-top: 10px;
				padding-bottom: 10px;
				&:hover {
					@media screen and (min-width: 1024px) {
						background-color: $main-theme-color;
						color: #fff;
					}
				}
			}
		}
	}
}

/* Show second and third menu levels */
@media screen and (min-width:768px) {

	.main-menu .sub-menu li{
		display: block;
	}

}

@media screen and (min-width: 1024px) {
	.main-menu .sub-menu .sub-menu{
		position: absolute;
		left: 100%;
		top: 0;
	}
}

/* Responsive menu button */
.nav-menu-icon {
	left: 5px;
	top: 0;
	width: 32px;
	height: 32px;
	display: none;
	cursor: pointer;
}

.nav-menu-icon-col {
	display: none;
}

/* Don`t reqiured */
.main-navigation.active .nav-menu-icon i {
	background: none;
}
.main-navigation.active .nav-menu-icon i:before {
	top: 0;
	transform: rotate(45deg);
}

.main-navigation.active .nav-menu-icon i:after {
	top: 0;
	transform: rotate(135deg);
}
/* End Don`t reqiured */

.nav-menu-icon i:before, .nav-menu-icon i:after {
	content: '';
	width: 32px;
	height: 2px;
	background: #000;
	position: absolute;
	left: 0;
	transition: all 0.15s ease-out;
}

.nav-menu-icon i:before {
	top: -7px;
}

.nav-menu-icon i:after {
	bottom: -7px;
}

.nav-menu-icon i {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 2px;
	background: #000;
}

.menu-item-has-children > a span {
	display: none;
}

/* Responsive menu */
@media screen and (max-width:1023px) {
	.nav-menu-icon,
	.nav-menu-icon-col {
		display: block;
	}

	.main-menu {
		display: none;
	}

	.main-navigation.active .main-menu {
		display: block;
		width: 100%;
		background-color: #fff;
		padding: 0;
		height: auto;
		margin-top: 15px;
	}

	.main-menu li {
		display: block;
	}

	.main-menu .sub-menu {
		position: static;
		padding: 5px 15px;
	}
	.menu-item.menu-item-has-children > a {
		display: inline-block;
		width: 100%;
	}

	.menu-item-has-children > a span{
		display: inline-block;
	}

	.menu-item-has-children.active > .sub-menu{
		display: block;
		position: static;
	}
}

.btn-search {
	margin-right: 10px;
	cursor: pointer;
	&:hover,
	&:focus {
		color: #000;
		outline: none;
		.icon-search {
			color: #000;
		}
	}
	.icon-search {
		color: $main-theme-color;
		font-size: 24px;
		transition: .3s;
	}
}

.menu-col {
	width: 100%;
}

.menu-search-col {
	position: relative;
	margin-top: 20px;
	display: none;
	@include large-size {
		margin-top: 0;
	}
	.search-form {
		top: 24px;
		right: -44px;
		background-color: $theme-color-green;
		padding: 15px;
		position: absolute;
		transform: scale(0.5);
		transform-origin: center;
		transition: 400ms cubic-bezier(.175,.885,.32,1.275);
		transition-property: transform;
		width: 250px;
		@include large-size {
			top: 44px;
		}
		input[type="search"] {
			background-color: $theme-color-green-light;
			border: none;
			line-height: 40px;
			margin-bottom: 0;
			width: 100%;
			padding-left: 13px;
			padding-right: 13px;
			@include font(13px, #fff);
			&:focus {
				outline: none;
			}
			&::placeholder {
				@include font(13px,#efefef);
				font-style: italic;
				font-family: $primary-font-family;
			}
		}
		&:before {
			content: "";
			position: absolute;
			border-style: solid;
			border-color: #2e2e30 transparent;
			display: block;
			width: 0;
			top: -7px;
			right: 20px;
			border-width: 0 7px 7px;
		}
	}
	&--active {
		.search-form {
			transform: scale(1);
		}
	}
}

// MEGA MENU
li.mega-menu-wrapper {
	position: static;
}
.main-menu {
	.mega-sub-menu {
		display: none;
		position: absolute;
		width: calc(100% - 40px);
		left: 20px;
		background-color: $vav-sl-bg-color;
		padding: 30px 0;
		box-shadow: inset 0 10px 5px -5px rgba(24,24,24,.3);
		@media screen and (max-width: 1023px) {
			position: static;
			width: 100%;
			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}
		&__section {
			width: 50%;
			float: left;
			&--two-thirds {
				width: 66.66%;
				.mega-sub-menu__list {
					@media screen and (max-width: 480px) {
						width: 50%;
					}
				}
			}
			&--one-thirds {
				width: 33.33%;
			}
			&--separator {
				position: relative;
				&:before {
					content: '';
					width: 1px;
					height: 100%;
					background-color: #4d4d4f;
					display: block;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
			@media screen and (max-width: 700px) {
				width: 100%;
				text-align: center;
			}
		}
		&__title {
			color: $vav-mm-title-txt-color;
			font-family: $vav-mm-title-font-family;
			font-size: $vav-mm-title-txt-size;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 15px;
			margin-left: 25px;
			@media screen and (max-width: 700px) {
				margin-left: 0;
			}
		}
		&__list {
			list-style: none;
			padding-right: 15px;
			margin: 0;
			width: 25%;
			float: left;
			&--two-cols {
				width: 50%;
			}
			@media screen and (max-width: 480px) {
				width: 100%;
				text-align: center;
			}
		}
		&__link {
			color: $vav-sl-txt-color;
			font-family: $vav-sl-font-family;
			font-size: $vav-sl-txt-size;
			font-weight: 400;
			line-height: 36px;
			padding: 0;
			padding-left: 10px;
			&:hover {
				@media screen and (min-width: 1024px) {
					background-color: $main-theme-color;
				}
			}
		}
	}
	.toggle-menu-item {
		float: right;
		transform: rotate(-90deg);
		transition: 150ms ease-in;
		transition-property: transform;
	}
}

// MOBILE MENU
.menu-item {
	&--active.menu-item {
		& > a {
			color: $vav-l1-txt-color-hover;
			background-color: $main-theme-color;
			.toggle-menu-item {
				transform: rotate(-270deg);
			}
		}
	}
}

.sticky-menu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	background-color: #fff;
	@media screen and (max-width: 1024px) {
		&--active {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 2;
			background-color: #fff;
			width: 100%;
			overflow-y: scroll;
			height: 100%;
		}
	}
}
