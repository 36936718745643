/* GENERAL STYLES */
* {
	margin: 0;
	padding: 0;
}

/* - - - - - */
/* Headings */
/* - - - - -*/
h1 {
	@include font($h1-txt-size, $h1-txt-color-normal, 400);
	font-family: $h1-font-family;
	a {
		@include font($h1-txt-size, $h1-txt-color-link);
		font-family: $h1-font-family;
		&:hover {
			color: $h1-txt-color-link-hover;
		}
	}
}

h2 {
	@include font($h2-txt-size, $h2-txt-color-normal, 400);
	font-family: $h2-font-family;
	margin-top: 30px;
	margin-bottom: 20px;
	a {
		@include font($h2-txt-size, $h2-txt-color-link);
		font-family: $h2-font-family;
		&:hover {
			color: $h2-txt-color-link-hover;
		}
	}
}

h3 {
	@include font($h3-txt-size, $h3-txt-color-normal, 400);
	font-family: $h3-font-family;
	margin-top: 35px;
	margin-bottom: 22px;
	a {
		@include font($h3-txt-size, $h3-txt-color-link);
		font-family: $h3-font-family;
		&:hover {
			color: $h3-txt-color-link-hover;
		}
	}
}

h4 {
	@include font($h4-txt-size, $h4-txt-color-normal, 400);
	font-family: $h4-font-family;
	margin-top: 26px;
	margin-bottom: 15px;
	a {
		@include font($h4-txt-size, $h4-txt-color-link);
		font-family: $h4-font-family;
		&:hover {
			color: $h4-txt-color-link-hover;
		}
	}
}

h5 {
	@include font($h5-txt-size, $h5-txt-color-normal, 400);
	font-family: $h5-font-family;
	margin-top: 38px;
	margin-bottom: 27px;
	a {
		@include font($h5-txt-size, $h5-txt-color-link);
		font-family: $h5-font-family;
		&:hover {
			color: $h5-txt-color-link-hover;
		}
	}
}

h6 {
	@include font($h6-txt-size, $h6-txt-color-normal, 400);
	font-family: $h6-font-family;
	margin-top: 47px;
	margin-bottom: 24px;
	a {
		@include font($h6-txt-size, $h6-txt-color-link);
		font-family: $h6-font-family;
		&:hover {
			color: $h6-txt-color-link-hover;
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	&:first-child {
		margin-top: 0;
	}
}

.title--serif {
	font-family: $primary-font-family;
	a {
		font-family: $primary-font-family;
	}
}

h3.title--serif {
	@include font($h3-txt-size-serif, $h3-txt-color-serif, 700);
	margin-top: 40px;
	margin-bottom: 27px;
	&:first-child {
		margin-top: 0;
	}
	a {
		@include font($h3-txt-size-serif, $h3-txt-color-serif);
		font-family: $primary-font-family;
		&:hover {
			color: $h3-txt-color-link-hover;
		}
	}
}

h4.title--serif {
	@include font($h4-txt-size-serif, $h4-txt-color-serif, 700);
	margin-top: 41px;
	&:first-child {
		margin-top: 0;
	}
	a {
		@include font($h4-txt-size-serif, $h4-txt-color-serif);
		font-family: $primary-font-family;
		&:hover {
			color: $h4-txt-color-link-hover;
		}
	}
}

/* - - - - */
/* Content */
/* - - - - */
body {
	font-family: $content-font-family;
	@include font($content-txt-size, $content-txt-color-normal);
}
p {
	font-family: $content-font-family;
	@include font($content-txt-size, $content-txt-color-normal);
	margin-bottom: 25px;
}
a {
	font-family: $content-font-family;
	@include font($content-txt-size, $content-txt-color-link);
	text-decoration: none;
	transition: color .4s;
	&:hover {
		text-decoration: none;
		color: $content-txt-color-link-hover;
	}
}

time {
	@include font(13px, #909090);
}

.link-arrow,
.download-link {
	@include font(16px,$main-theme-color,700);
	line-height: 17px;
	display: inline-block;
	position: relative;
	&:after,
	&:before {
		font-family: fontello;
		font-size: 12px;
		display: inline-block;
	}
}

.link-arrow {
	&:hover:after {
		margin-left: 10px;
	}
	&:after {
		content: '\ede6';
		margin-left: 5px;
		transition: margin-left 250ms ease-in;
	}
}

.download-link {
	margin-top: 10px;
	&:before {
		content: '\ede4';
		margin-left: 0;
		margin-right: 5px;
		transition: transform 250ms ease-in;
	}
	&:hover:before {
		transform: translateY(5px);
	}
	&:after {
		content: none;
	}
}

label {
	display: block;
	margin: 0;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 2.4375rem;
	margin: 0 0 1rem;
	padding: 0.5rem;
	border: 1px solid #cacaca;
	border-radius: 0;
	transition: border-color 0.25s ease-in-out;
	&:focus {
		border: 1px solid #8a8a8a;
		outline: none;
	}
}

select {
	background-origin: content-box;
	background-position: right -1rem center;
	background-repeat: no-repeat;
	background-size: 9px 6px;
	appearance: none;
}

.light-grey-bg {
	background-color: #fafafa;
}

/* - - - */
/* Lists */
/* - - - */
ul {
	list-style: none;
	li {
		position: relative;
		color: $content-txt-color-normal;
		&:before {
			content: '\2022';
			position: absolute;
			max-width: 0;
			max-height: 0;
			left: -19px;
			top: -7px;
			font-size: 24px;
			color: $main-theme-color;
		}
	}
	&--no-b-marg {
		margin-bottom: 0;
	}
	&--no-bullets {
		padding-left: 0;
		li {
			&:before {
				display: none;
			}
		}
	}
}

ol,
ul {
	padding-left: 18px;
	margin-top: 1em;
	margin-bottom: 1em;
	li {
		line-height: 1.6;
	}
}

/* - - - -*/
/* Tables */
/* - - - -*/
.table-wrapper {
	overflow-x: auto;
	@include medium-size {
		overflow-x: initial;
	}
}
table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	margin-top: 30px;
	margin-bottom: 24px;
	tr {
		border-bottom: 1px solid rgba(0,0,0,.1);
	}
	thead,
	tfoot {
		background-color: #f7f7f7;
		border: none;
		color: #000;
		th,
		td {
			padding: 1.2rem 1.9rem 1.1rem;
			text-align: left;
		}
		tr {
			border-bottom: 2px solid rgba(0,0,0,.1);
		}
	}
	tbody {
		th,
		td {
			padding: .4rem 1.9rem .4rem;
			color: $content-color-minor;
		}
		tr {
			background-color: #f7f7f7;
			cursor: pointer;
			&:nth-child(even) {
				background-color: #f7f7f7;
				border-bottom: 1px solid rgba(0,0,0,.1);
			}
			&:last-child {
				border: none;
			}
			&:hover {
				background-color: $content-color-minor;
				td {
					color: #fff;
				}
			}
		}
	}
}

/* - - - - */
/* Buttons */
/* - - - - */
.btn {
	display: inline-block;
	position: relative;
	font-weight: 700;
	font-family: $primary-font-family;
	font-size: 16px;
	padding: 14px 20px;
	color: white;
	background-color: $main-theme-color;
	border: none;
	z-index: 1;
	vertical-align: middle;
	box-shadow: inset 0 0 0 2px $main-theme-color, 0 0 1px transparent;
	transition: all .3s;
	&:hover,
	&:focus,
	&:active {
		color: #fff;
		&:after {
			width: 100%;
		}
	}
	&:after {
		width: 0%;
		height: 100%;
		top: 0;
		left: 0;
		background: #2e2e30;
		content: '';
		position: absolute;
		z-index: -1;
		transition: all 0.3s;
	}
	&--small {
		font-size: 14px;
		padding: 10px 15px;
		&.btn--ghost {
			padding-right: 26px;
			&:before {
				font-size: 9px;
				right: 14px;
				margin-top: 4px;
			}
		}
	}
	&--large {
		font-size: 20px;
		padding: 17px 25px;
		&.btn--ghost {
			padding-right: 43px;
			&:before {
				right: 24px;
				font-size: 14px;
				margin-top: 4px;
			}
		}
	}
	&--small-medium {
		font-size: 16px;
		margin-top: 20px;
		margin-bottom: 50px;
		padding-top: 10px;
		padding-bottom: 11px;
		@include medium-size {
			margin-top: 0;
		}
		@include large-size {
			margin-bottom: 0;
		}
	}
	&--ghost {
		background-color: transparent;
		color: $main-theme-color;
		position: relative;
		padding-right: 35px;
		transform: perspective(1px) translateZ(0);
		transition-duration: 0.1s;
		box-shadow: inset 0 0 0 2px #d2d2d2, 0 0 1px transparent;
		&:before {
			content: '\ede6';
			font-family: "fontello";
			font-size: 12px;
			position: absolute;
			right: 20px;
			margin-top: 3px;
			transform: translateZ(0);
			transition-duration: 0.1s;
			transition-property: transform;
			transition-timing-function: ease-out;
			transition-delay: .25s;
		}
		&:hover,
		&:focus,
		&:active {
			&:before {
				transform: translateX(4px);
			}
		}
	}
}
button[type="submit"] {
	cursor: pointer;
}

.dark-section {
	.btn {
		&:hover,
		&:focus,
		&:active {
			color: $main-theme-color;
			&:after {
				background-color: #fff;
			}
		}
	}
}

.socials {
	*[class^=icon-] {
		color: $bb-txt-color-normal;
		width: 35px;
		height: 35px;
		font-size: 18px;
		border-radius: 50%;
		text-align: center;
		transition: .2s;
		display: inline-block;
		&:before {
			line-height: 35px;
		}
		&:hover {
			color: #fff;
		}
	}
	> a {
		display: inline-block;
	}
	&--slide-hover {
		max-height: 30px;
		overflow: hidden;
		*[class^=icon-] {
			transform: translateY(-100%);
			display: block;
		}
		a {
			line-height: 30px;
			display: inline-block;
			margin-right: -4px;
			&:hover {
				*[class^=icon-]:not(.icon-hover) {
					transform: translateY(0);
				}
				.icon-hover {
					transform: translateY(0);
					color: #fff;
				}
			}
		}
		&.icon-hover {
			color: #fff;
			transform: translateY(-100%);
		}
	}
	&--medium-marg {
		@media screen and (max-width: 1024px) {
			margin-top: 20px;
		}
	}
}

.row-logo {
	height: 100px;
	margin-top: 10px;
	margin-bottom: 10px;
	@include medium-size {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.contact-phone {
	color: $content-txt-color-normal;
	font-size: 15px;
	@include medium-size {
		margin-right: 8px;
	}
	span {
		color: $content-txt-color-link-hover;
		font-size: 15px;
	}
	.icon-phone-3 {
		color: $bb-txt-color-normal;
		font-size: 18px;
	}
	&--home-2 {
		color: $bb-txt-color-normal;
		span {
			color: $bb-txt-color-normal;
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	&.banner-title {
		margin: 30px 0 20px;
		text-align: center;
		font-size: 30px;
		@include medium-size {
			margin: 37px 0 27px;
		}
		@include large-size {
			margin: 67px 0 47px;
		}
	}
}
h1, h2, h3, h4, h5, h6 {
	&.block-title {
		margin: 40px 0 28px;
		@media screen and (max-width: 1024px) {
			margin: 15px 0 20px;
		}
		&--no-t-marg {
			margin-top: 0;
		}
		&--center {
			text-align: center;
		}
	}
}

.s-back-switch {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

/* - - - - - -*/
/* Blockquote */
/* - - - - - -*/
.blockquote,
blockquote {
	border-left: none;
	margin: 27px 0 20px;
	font-family: $alternative-font-family;
	@include font(18px, #303133, 700);
	position: relative;
	font-style: italic;
	@include large-size {
		padding-left: 52px;
		line-height: 30px;
	}
	&::before {
		content: open-quote;
		@include font(72px, $main-theme-color);
		line-height: 0;
		position: relative;
		display: inline-block;
		bottom: 15px;
		left: -12px;
	}
	&:after {
		color: transparent;
		content: close-quote;
		position: absolute;
	}
}

.blockquote {
	&-author__name,
	&-author__position {
		&:before {
			display: none;
		}
	}
	&-author__name {
		@include font(18px, $main-theme-color,700);
		line-height: 1;
		font-style: normal;
		font-family: $primary-font-family;
	}
	&-author__position {
		@include font(16px, #909090,400);
		margin-top: 5px;
	}
	&-author {
		display: flex;
		align-items: center;
	}
	&-author__photo {
		max-width: 45px;
		max-height: 45px;
		height: auto;
		margin-right: 15px;
		margin-bottom: 0;
	}
	&-author__info {
		display: flex;
		flex-direction: column;
	}
	&--style-1 {
		background-color: #f7f7f7;
		padding: 23px 30px 35px;
		&, p {
			font-weight: 400;
			font-size: 17px;
			@include large-size {
				line-height: 28px;
			}
		}
		p {
			display: inline;
			margin: 0;
			padding: 0;
		}
		&:after {
			position: absolute;
			width: 0;
			height: 0;
			right: 0;
			bottom: -20px;
			border: 10px solid;
			border-color: #ebebeb transparent transparent #ebebeb;
		}
	}
	&--style-2 {
		padding: 0;
		@include large-size {
			padding-left: 0;
		}
	}
}
@import 'mark';
@import 'widgets';
