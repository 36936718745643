/* ACCORDION */
.accordion {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 45px;
}

.accordion-title {
	@include font(16px,$content-color-minor);
	font-family: $titles-font-family;
	padding-left: 50px;
	padding-top: 13px;
	padding-bottom: 13px;
	border: none;
	border-top: 1px solid $content-color-minor;
	margin: 0;
	position: relative;
	cursor: pointer;
	&::before {
		background-color: $content-color-minor;
		left: 0;
		top: 50%;
		display: inline-block;
		position: absolute;
		width: 30px;
		height: 30px;
		content: '\e845';
		font-family: 'fontello';
		color: #fff;
		text-align: center;
		line-height: 30px;
		transform: translateY(-50%);
		margin-top: 0;
	}
	&:hover,
	&:focus {
		color: $content-color-minor;
		outline: none;
		background-color: transparent;
	}
}

.accordion-content {
	border: none;
	line-height: 24px;
	color: $content-txt-color-normal;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	height: 0;
	transition: height .3s linear, padding-top .2s linear, padding-bottom .3s linear;
}

.accordion-item {
	margin-left: 0;
	&:before {
		content: none;
	}
	&:last-child {
		.accordion-content {
			border-bottom: 1px solid $content-color-minor;
		}
	}
	&.active {
		> .accordion-title {
			&::before {
				content: '\e849';
				background-color: $main-theme-color;
			}
		}
		.accordion-content {
			width: initial;
			height: initial;
			padding-bottom: 2.7rem;
			padding-top: .9rem;
		}
	}
}