/* CLIENT ITEM */
.client-item-slider {
	margin-bottom: 25px;
	padding-left: 1px;
	padding-right: 1px;
	.swiper-wrapper {
		box-sizing: border-box;
	}
}

.client-item {
	border: 1px solid #f0f0f0;
	text-align: center;
	display: flex;
	min-height: 110px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color .3s;
	&:hover {
		background-color: #fbfbfb;
	}
	img {
		margin: auto;
	}
}