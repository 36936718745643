/* COMMENT FORM */
.comment-form {
	margin-top: 18px;
	@include small-size {
		margin-bottom: 50px;
	}
	input {
		max-width: 450px;
	}
	textarea {
		height: 240px;
		resize: none;
	}
	&__title {
		margin-bottom: 40px;
	}
	button[type="submit"] {
		margin-top: 10px;
	}
}