/* OUR LOCATION */
.our-location {
	&__map {
		height: 220px;
		margin: 28px 0 24px;
	}
}

.experience-location {
	margin-top: 50px;
	@include large-size {
		margin-top: 91px;
	}
	&__title {
		margin-bottom: 22px;
		@include small-size {
			text-align: center;
		}
	}
	.our-location {
		text-align: center;
		margin-bottom: 50px;
		margin-top: 30px;
		@include medium-size {
			margin-bottom: 0;
			margin-top: 0;
			text-align: left;
		}
	}
	p {
		margin-bottom: 0;
	}
}