/* FORM ELEMENTS */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
select,
textarea {
  background-color: #f5f5f5;
  border-color: transparent;
  box-shadow: none;
  margin-bottom: 10px;
  &:focus {
    background-color: #f5f5f5;
    box-shadow: none;
  }
}
label {
  color: #777;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
select {
  padding-right: 2rem;
  background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAQCAMAAABA3o1rAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA0ppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzkyNkJFNTZCN0JEMTFFNkE2MDNGNjNGMkFCQ0M1NUUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzkyNkJFNTVCN0JEMTFFNkE2MDNGNjNGMkFCQ0M1NUUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo1YjZmNjk1OC1iN2JkLTExZTYtOTEzMy1iN2MzMWZmYjMzYTAiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo1YjZmNjk1OC1iN2JkLTExZTYtOTEzMy1iN2MzMWZmYjMzYTAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6+uV7IAAAABlBMVEW1tbUAAAAd6wLFAAAAAnRSTlP/AOW3MEoAAABHSURBVHjahMrJEQAgCMBA03/TjjcgR76b1qqomPRgDGQ+BxJfA7HvgdDPQOR3IPA34LsYcF0OeK4GHNcDv5uBz+2AdboAAwCh6ADxwANGWwAAAABJRU5ErkJggg==');
}

.search-form {
  position: relative;
  z-index: 1;
  &__submit {
    margin-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 12px;
    cursor: pointer;
  }
}

.dark-section {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  select,
  textarea {
    background-color: #232325;
    color: $footer-txt-color-normal;
    &:focus {
      border: 1px solid #707071;
    }
  }
  label {
    color: $footer-txt-color-normal;
  }
}