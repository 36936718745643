@import 'settings';
@import 'vars';
@import 'mixins';
@import 'init';
@import 'elements';
@import 'common';

/* - - - - - - - - - - - - - - - - - - - - */
/* Header */
/* - - - - - - - - - - - - - - - - - - - - */
.main-header {
	position: relative;
	z-index: 6;
}

.sticky-container {
	position: relative;
	z-index: 20;
}
.row-fluid {
	&--menu {
		border-top: 1px solid #dadada;
		box-shadow: 0 4px 2px -2px rgba(107,107,107,.3);
		.menu-search-row {
			margin: 0;
			padding: 15px 0;
			height: 100%;
		}
		.top-bar,
		.title-bar {
			width: 100%;
		}
		.title-bar {
			justify-content: flex-start;
			.menu-icon {
				margin-left: .35rem;
				margin-right: .35rem;
				&:after {
					background: #cacaca;
					box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
				}
			}
		}
		.top-bar {
			z-index: 10;
			.top-bar-right {
				order: -1;
			}
			@media screen and (min-width: 40em) {
				flex-wrap: wrap;
				.top-bar-left {
					flex: 0 0 100%;
				}
				.top-bar-right {
					flex: 1 0 100%;
				}
			}
			@media screen and (min-width: 64em) {
				.top-bar-left {
					flex: 1 0 auto;
				}
				.top-bar-right {
					flex: 0 1 auto;
					order: 0;
				}
			}
		}
	}
	&--socials {
		//background-color: $tb-bg-color;
		background-color: $theme-color-green;
        #logoHeader {
            display: none;
            @include small-size {
                display: inline-block;
                margin: 0 1rem 0 .7rem;
            }
        }

		> .row {
			@include small-size {
				flex-direction: column;
				padding: 15px 0;
			}
		}
		.socials {
			display: inline-flex;
			a {
				display: inline-block;
			}
			*[class^=icon-] {
				border-radius: 0;
				font-size: 14px;
				width: 30px;
				height: 30px;
				display: block;
				&:before {
					line-height: 30px;
				}
			}
		}
	}
}

.header-3 {
	.row-fluid {
		&--socials {
			background-color: #e1e1e1;
		}
		&--menu {
			border: none;
			box-shadow: none;
		}
		.row {
			&--menu-bg {
				margin: 0;
				background-color: #3a3a3c;
				box-shadow: 0 4px 2px -2px rgba(107,107,107,.3);
			}
		}
	}
	.main-menu {
		> li {
			> a {
				color: #fff;
				background-color: #3a3a3c;
				&:after {
					opacity: .2;
					z-index: 1;
				}
			}
		}
	}
	.nav-menu-icon {
		i {
			background-color: #fff;
			&:before,
			&:after {
				background-color: #fff;
			}
		}
	}
}

@import 'modules/nav';
@import 'modules/tile';
@import 'modules/hover-effects';
@import 'modules/forms';
@import 'modules/latest-posts';
@import 'modules/get-a-quote';
@import 'modules/find-people-map';
@import 'modules/services-links';
@import 'modules/main-footer';
@import 'modules/subscribe-widget';
@import 'modules/address';
@import 'modules/tags';
@import 'modules/accordion';
@import 'modules/tabs';
@import 'modules/drop-caps';
@import 'modules/separators';
@import 'modules/about-section';
@import 'modules/alert-boxes';
@import 'modules/client-item';
@import 'modules/contact-form';
@import 'modules/gallery';
@import 'modules/twitter-widget';
@import 'modules/latest-post-thumb';
@import 'modules/widget-blockquote';
@import 'modules/our-location';
@import 'modules/news';
@import 'partials/page-heading';
@import 'modules/single-people';
@import 'modules/play-btn';
@import 'modules/about-article';
@import 'modules/single-location';
@import 'modules/faq-item';
@import 'modules/comment-form';
@import 'modules/contact';
@import 'modules/carrers';
@import 'modules/pagination';
