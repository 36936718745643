/* WIDGET */
.widget {
	margin-bottom: 30px;
	@include large-size {
		margin-bottom: 55px;
	}
	&-title {
		@include font(17px, $content-color-minor, 700);
		font-family: $primary-font-family;
		a {
			font-family: $primary-font-family;
		}
		&--margins-2 {
			margin-bottom: 25px;
		}
	}
	ul {
		padding-left: 0;
		list-style: none;
		margin-left: 0;
		li {
			padding: 5px 0;
			padding-right: 10px;
			border-bottom: 1px solid $border-color;
			&:before {
				content: none;
			}
			&:last-child {
				border-bottom: none;
			}
			a {
				@include font($cat-links-txt-size, $cat-links-txt-color-normal);
				&:hover {
					color: $cat-links-txt-color-hover;
				}
				&.widget__date {
					font-family: $primary-font-family;
					@include font(13px,#909090,400);
					&:hover {
						color: #000;
					}
				}
			}
			h4 {
				line-height: 24px;
				margin-bottom: 10px;
				margin-top: -5px;
				a {
					@include font(16px,$content-color-minor,700);
					font-family: $primary-font-family;
					&:hover {
						color: $main-theme-color;
					}
				}
			}
		}
	}
}

.dark-section {
	.widget {
		ul {
			li {
				border-bottom: 1px solid $footer-border-color;
				&:last-child {
					border-bottom: none;
				}
				color: $theme-color-green-light;
				a {
					color: $footer-txt-color-normal;
					&:hover {
						color: $theme-color-green-light;
					}
				}
			}
		}
		h4 {
			a {
				color: $footer-txt-color-normal;
			}
		}
	}
}
